import React from "react";
import ContactUs from "./components/ContactUs";
import CustomAppBar from "./components/CustomAppBar";
import Footer from "./components/Footer";
import HomeBanner from "./components/HomeBanner";
import OurProcess from "./components/OurProcess";
import OurServices from "./components/OurServices";
import OurStory from "./components/OurStory";

export default function Website(props) {
  return (
    <React.Fragment>
      <CustomAppBar />
      <HomeBanner />
      <OurStory />
      <OurProcess />
      <OurServices />
      <ContactUs />
      <Footer />
    </React.Fragment>
  );
}
