import React from "react";
import _ from "lodash";
import { grey } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  content: {
    overflowY: "auto",
    width: 400,
    height: 400,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white
  },
  header: {
    color: theme.palette.primary.light
  },
  nested: {
    color: grey[700],
    paddingLeft: theme.spacing(2)
  },
  subheader: {
    color: theme.palette.common.white,
  }
}));

const initialState = {
  viableProducts: {
    "Skin Care (Face)": {
      isOpen: false,
      items: [
        "Cleansers",
        "Creams & Moisturizers",
        "Exfoliators",
        "Face Treatments",
        "Toners & Astringents"
      ]
    },
    "Skin Care (Eyes)": {
      isOpen: false,
      items: ["Creams", "Masks"]
    },
    "Skin Care (Body)": {
      isOpen: false,
      items: ["Foot Care", "Moisturizers"]
    },
    Minerals: {
      isOpen: false,
      items: [
        "Calcium",
        "Chromium",
        "Colloidal Minerals",
        "Copper",
        "Iodine",
        "Iron",
        "Magnesium",
        "Manganese",
        "Multiminerals",
        "Potassium",
        "Selenium",
        "Silicon",
        "Sodium",
        "Trace Minerals",
        "Zinc"
      ]
    },
    "Herbal Supplements": {
      isOpen: false,
      items: [
        "Alfalfa",
        "Aloe Vera",
        "Artichoke",
        "Astragalus",
        "Bilberry",
        "Black Cohosh",
        "Cayenne",
        "Chamomile",
        "Chlorella",
        "Chlorophyll",
        "Cinnamon",
        "Cranberry",
        "Curcumin",
        "Dong Quai",
        "Echinacea",
        "Elderberry",
        "Evening Primrose",
        "Flaxseed",
        "Garlic",
        "Ginger",
        "Ginkgo Biloba",
        "Ginseng",
        "Goldenseal",
        "Grape Seed Extract",
        "Green Tea",
        "Hawthorn",
        "Horny Goat Weed",
        "Kava Kava",
        "Licorice Root",
        "Marshmallow Root",
        "Milk Thistle",
        "Muira Puama",
        "Mushrooms",
        "Neem",
        "Nettle",
        "Noni",
        "Olive Leaf",
        "Oregano",
        "Red Yeast Rice",
        "Saw Palmetto",
        "Spirulina",
        "St. John's Wort",
        "Stevia",
        "Tribulus",
        "Triphala",
        "Turmeric",
        "Valerian",
        "Yohimbe"
      ]
    },
    Vitamins: {
      isOpen: false,
      items: [
        "Blended Vitamin & Mineral Supplements",
        "Choline",
        "Flavonoids",
        "Multivitamins",
        "Prenatal Vitamins",
        "Vitamin A",
        "Vitamin B",
        "Vitamin C",
        "Vitamin D",
        "Vitamin E"
      ]
    }
  }
};

export default function ViableProductsCard(props) {
  const classes = useStyles();
  const [state, setState] = React.useState(initialState);

  const handleOpenToggle = category => {
    setState({
      ...state,
      viableProducts: {
        ...state.viableProducts,
        [category]: {
          ...state.viableProducts[category],
          isOpen: !state.viableProducts[category].isOpen
        }
      }
    });
  };

  return (
    <Card>
      <CardContent className={classes.content}>
        <Typography
          gutterBottom
          variant="h6"
          component="h2"
          className={classes.header}
        >
          Viable Products List
        </Typography>
        <List>
          {_.toPairs(state.viableProducts).map(([categoryName, info]) => (
            <React.Fragment key={categoryName}>
              <ListItem
                button
                className={classes.subheader}
                onClick={event => handleOpenToggle(categoryName)}
              >
                <ListItemText primary={categoryName} />
                {info.isOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={info.isOpen} timeout="auto" unmountOnExit>
                {info.items.map(item => (
                  <List component="div" disablePadding key={item}>
                    <ListItem>
                      <ListItemText primary={item} className={classes.nested} />
                    </ListItem>
                  </List>
                ))}
              </Collapse>
            </React.Fragment>
          ))}
        </List>
      </CardContent>
    </Card>
  );
}
