import React from "react";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1)
  },
  root: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    marginBottom: theme.spacing(4)
  }
}));

export default function FiltersBar(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container alignItems="center" spacing={3}>
        {props.userType !== "SALES_AGENT" && (
          <Grid item xs={2}>
            <FormControl className={classes.formControl} fullWidth>
              <InputLabel shrink id="sales-person-label">
                Sales Person
              </InputLabel>
              <Select
                labelId="sales-person-select-label"
                id="sales-person-select"
                value={props.salesPerson}
                onChange={props.actions.handleSalesPersonChange}
                className={classes.select}
              >
                <MenuItem value="ALL">All</MenuItem>
                {_.toPairs(props.users)
                  .filter(([id, info]) => {
                    if (["SALES_AGENT"].includes(info.type)) return true;
                    return false;
                  })
                  .map(([id, info]) => (
                    <MenuItem
                      key={id}
                      value={id}
                    >{`${info.name} ${info.surname}`}</MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        )}
      </Grid>
    </div>
  );
}
