import React from "react";
import { grey } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  name: {
    color: theme.palette.common.white,
    fontSize: 24,
    fontWeight: "bold",
    display: "block"
  },
  title: {
    color: grey[700],
    textTransform: "lowercase",
    display: "block"
  },
  userInfoWrapper: {
    padding: theme.spacing(2)
  }
}));

export default function UserInfo(props) {
  const classes = useStyles();

  return (
    <div className={classes.userInfoWrapper}>
      <span className={classes.name}>{props.name}</span>
      <span className={classes.title}>{props.title}</span>
    </div>
  );
}
