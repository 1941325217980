import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Route, Switch } from "react-router-dom";
import ComingSoon from "./components/ComingSoon";
import Dashboard from "./components/Dashboard";
import EmailCampaigns from "./components/EmailCampaigns";
import Leads from "./components/Leads";
import LinearProgress from "@material-ui/core/LinearProgress";
import Sales from "./components/Sales";

const useStyles = makeStyles(theme => ({
  content: {
    flexGrow: 1,
    marginTop: theme.spacing(8)
  }
}));

export default function SectionContent(props) {
  const classes = useStyles();

  return (
    <main className={classes.content}>
      {props.isLoading && <LinearProgress color="secondary" />}
      {!props.isUserLoading && (
        <Switch>
          <Route path="/staff/dashboard">
            <Dashboard isLoading={props.isLoading} userType={props.userType} />
          </Route>
          <Route path="/staff/sales">
            <Sales
              isLoading={props.isLoading}
              businessInfo={props.businessInfo}
              userId={props.userId}
              userType={props.userType}
              salesRecords={props.salesRecords}
              users={props.users}
              actions={{
                loadSalesRecords: (
                  recordType,
                  salesPersonId,
                  fromDate,
                  toDate
                ) =>
                  props.actions.loadSalesRecords(
                    recordType,
                    salesPersonId,
                    fromDate,
                    toDate
                  ),
                loadBusinessInfo: id => props.actions.loadBusinessInfo(id),
                loadBusinessContacts: id =>
                  props.actions.loadBusinessContacts(id),
                restoreContact: id => props.actions.restoreContact(id),
                deleteContact: id => props.actions.deleteContact(id),
                makeContactPrimary: (id, otherContacts) =>
                  props.actions.makeContactPrimary(id, otherContacts),
                editContactDetails: (id, newDetails) =>
                  props.actions.editContactDetails(id, newDetails),
                makeLeadProspect: id => props.actions.makeLeadProspect(id),
                makeLeadLost: id => props.actions.makeLeadLost(id),
                makeLeadClient: id => props.actions.makeLeadClient(id),
                logNewContactDate: id => props.actions.logNewContactDate(id),
              }}
            />
          </Route>
          <Route path="/staff/email-campaigns">
            <EmailCampaigns
              isLoading={props.isLoading}
              businessInfo={props.businessInfo}
              userId={props.userId}
              userType={props.userType}
              emailableRecords={props.emailableRecords}
              users={props.users}
              actions={{
                createNewCampaign: (businessIds, senderInfo, templateId) =>
                  props.actions.createNewCampaign(
                    businessIds,
                    senderInfo,
                    templateId
                  ),
                createNewContact: (
                  businessId,
                  businessName,
                  name,
                  surname,
                  jobTitle,
                  email,
                  phoneNumber
                ) =>
                  props.actions.createNewContact(
                    businessId,
                    businessName,
                    name,
                    surname,
                    jobTitle,
                    email,
                    phoneNumber
                  ),
                loadBusinessInfo: id => props.actions.loadBusinessInfo(id),
                loadBusinessContacts: id =>
                  props.actions.loadBusinessContacts(id),
                restoreContact: id => props.actions.restoreContact(id),
                deleteContact: id => props.actions.deleteContact(id),
                makeContactPrimary: (id, otherContacts) =>
                  props.actions.makeContactPrimary(id, otherContacts),
                editContactDetails: (id, newDetails) =>
                  props.actions.editContactDetails(id, newDetails),
                loadEmailableRecords: (
                  contactCount,
                  leadType,
                  lastContacted,
                  fromDate,
                  toDate
                ) =>
                  props.actions.loadEmailableRecords(
                    contactCount,
                    leadType,
                    lastContacted,
                    fromDate,
                    toDate
                  ),
                editBusinessName: (id, businessName) =>
                  props.actions.editBusinessName(id, businessName),
                editWebsiteUrl: (id, websiteUrl) =>
                  props.actions.editWebsiteUrl(id, websiteUrl),
                editLeadType: (id, leadType) =>
                  props.actions.editLeadType(id, leadType)
              }}
            />
          </Route>
          <Route path="/staff/leads">
            <Leads
              isLoading={props.isLoading}
              businessInfo={props.businessInfo}
              userId={props.userId}
              userName={props.userName}
              userType={props.userType}
              leads={props.leads}
              users={props.users}
              actions={{
                createNewLead: (
                  businessInfo,
                  leadType,
                  creatorInfo,
                  primaryContactInfo
                ) =>
                  props.actions.createNewLead(
                    businessInfo,
                    leadType,
                    creatorInfo,
                    primaryContactInfo
                  ),
                createNewContact: (
                  businessId,
                  businessName,
                  name,
                  surname,
                  jobTitle,
                  email,
                  phoneNumber
                ) =>
                  props.actions.createNewContact(
                    businessId,
                    businessName,
                    name,
                    surname,
                    jobTitle,
                    email,
                    phoneNumber
                  ),
                restoreContact: id => props.actions.restoreContact(id),
                deleteContact: id => props.actions.deleteContact(id),
                loadBusinessInfo: id => props.actions.loadBusinessInfo(id),
                makeContactPrimary: (id, otherContacts) =>
                  props.actions.makeContactPrimary(id, otherContacts),
                loadBusinessContacts: id =>
                  props.actions.loadBusinessContacts(id),
                validateBusinessName: name =>
                  props.actions.validateBusinessName(name),
                validateBusinessWebsite: websiteUrl =>
                  props.actions.validateBusinessWebsite(websiteUrl),
                validateContactEmail: email =>
                  props.actions.validateContactEmail(email),
                validateContactPhoneNumber: phoneNumber =>
                  props.actions.validateContactPhoneNumber(phoneNumber),
                validateContactNameSurname: text =>
                  props.actions.validateContactNameSurname(text),
                loadLeads: (status, createdBy, type, fromDate, toDate) =>
                  props.actions.loadLeads(
                    status,
                    createdBy,
                    type,
                    fromDate,
                    toDate
                  ),
                editContactDetails: (id, newDetails) =>
                  props.actions.editContactDetails(id, newDetails),
                cleanBusinessRecord: id =>
                  props.actions.cleanBusinessRecord(id),
                editBusinessName: (id, businessName) =>
                  props.actions.editBusinessName(id, businessName),
                editWebsiteUrl: (id, websiteUrl) =>
                  props.actions.editWebsiteUrl(id, websiteUrl),
                editLeadType: (id, leadType) =>
                  props.actions.editLeadType(id, leadType),
                reviewLead: (id, feedback) =>
                  props.actions.reviewLead(id, feedback)
              }}
            />
          </Route>
          <Route path="/staff">
            <ComingSoon />
          </Route>
        </Switch>
      )}
    </main>
  );
}
