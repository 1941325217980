import React from "react";
import { forwardRef } from "react";
import { common } from "@material-ui/core/colors";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { makeStyles } from "@material-ui/core/styles";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Button from "@material-ui/core/Button";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import CircularProgress from "@material-ui/core/CircularProgress";
import Clear from "@material-ui/icons/Clear";
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import MaterialTable from "material-table";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <Delete {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => (
    <FirstPage {...props} style={{ color: common["white"] }} ref={ref} />
  )),
  LastPage: forwardRef((props, ref) => (
    <LastPage {...props} style={{ color: common["white"] }} ref={ref} />
  )),
  NextPage: forwardRef((props, ref) => (
    <ChevronRight {...props} style={{ color: common["white"] }} ref={ref} />
  )),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} style={{ color: common["white"] }} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => (
    <Clear {...props} style={{ color: common["white"] }} ref={ref} />
  )),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const useStyles = makeStyles(theme => ({
  loadingOverlay: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: fade(theme.palette.common.black, 0.8)
  }
}));

const headerStyle = {
  backgroundColor: "#171415"
};

export default function ContactsTable(props) {
  const classes = useStyles();

  const getColumns = () => {
    const columns = [
      {
        headerStyle,
        title: "Email Address",
        field: "emailAddress"
      },
      {
        headerStyle,
        title: "Name",
        field: "name"
      },
      {
        headerStyle,
        title: "Surname",
        field: "surname"
      },
      {
        headerStyle,
        title: "Phone Number",
        field: "phoneNumber"
      },
      {
        headerStyle,
        title: "Job Title",
        field: "jobTitle"
      },
      {
        headerStyle,
        title: "Primary Contact",
        field: "isPrimary",
        type: "boolean",
        editable: "never"
      },
      {
        headerStyle,
        title: "Actions",
        field: "actionButtons",
        editable: "never",
        sorting: false,
        render: rowData => {
          if (rowData.status === "DELETED") {
            return (
              <Button
                variant="contained"
                color="primary"
                onClick={() => props.actions.restoreContact(rowData.id)}
              >
                Restore
              </Button>
            );
          } else if (!rowData.isPrimary) {
            return (
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  props.actions.makeContactPrimary(
                    rowData.id,
                    props.data
                      .map(info => info.id)
                      .filter(id => id !== rowData.id)
                  )
                }
              >
                Set as Primary
              </Button>
            );
          }
        }
      }
    ];

    return columns;
  };

  const getFilteredData = (data, showDeleted) => {
    return data.filter(info => {
      if (!showDeleted && info.status === "DELETED") {
        return false;
      } else {
        return true;
      }
    });
  };

  const filteredData = getFilteredData(props.data, props.showDeleted);

  return (
    <React.Fragment>
      <MaterialTable
        isLoading={props.isLoading}
        icons={tableIcons}
        columns={getColumns()}
        data={filteredData}
        options={{
          headerStyle,
          pageSizeOptions: [],
          grouping: false,
          draggable: false
        }}
        title="Business Contacts"
        editable={{
          isDeletable: rowData => rowData.status === "ACTIVE",
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              props.actions.deleteContact(oldData.id);
              resolve();
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              props.actions.editContactDetails(newData.id, {
                name: newData.name,
                surname: newData.surname,
                phoneNumber: newData.phoneNumber,
                role: newData.jobTitle,
                email: newData.emailAddress
              });
              resolve();
            })
        }}
        components={{
          OverlayLoading: props => (
            <div className={classes.loadingOverlay}>
              <CircularProgress color="primary" size={32} />
            </div>
          )
        }}
        localization={{
          header: {
            actions: ""
          },
          body: {
            emptyDataSourceMessage: "No contacts to display"
          }
        }}
      />
    </React.Fragment>
  );
}
