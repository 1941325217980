import React from 'react';
import _ from 'lodash';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
  },
  root: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    marginBottom: theme.spacing(4),
  },
}));

export default function FiltersBar(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container alignItems="center" spacing={3}>
        {props.userType !== 'LEADS_AGENT' && props.userType !== 'SALES_AGENT' && (
          <Grid item xs={2}>
            <FormControl className={classes.formControl} fullWidth>
              <InputLabel shrink id="creator-label">
                Created By
              </InputLabel>
              <Select
                labelId="creator-select-label"
                id="creator-select"
                value={props.createdBy}
                onChange={props.actions.handleCreatedByChange}
                className={classes.select}
              >
                <MenuItem value="ALL">All</MenuItem>
                {_.toPairs(props.users)
                  .filter(([id, info]) => {
                    if (
                      ['LEADS_AGENT', 'LEADS_MANAGER', 'SALES_AGENT'].includes(
                        info.type
                      )
                    )
                      return true;
                    return false;
                  })
                  .map(([id, info]) => (
                    <MenuItem
                      key={id}
                      value={id}
                    >{`${info.name} ${info.surname}`}</MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        <Grid item xs={2}>
          <FormControl className={classes.formControl} fullWidth>
            <InputLabel shrink id="select-type-label">
              Type
            </InputLabel>
            <Select
              labelId="type-select-label"
              id="type-select"
              value={props.type}
              onChange={props.actions.handleTypeChange}
            >
              <MenuItem value="ALL">All</MenuItem>
              <MenuItem value="COLD">Cold</MenuItem>
              <MenuItem value="WARM">Warm</MenuItem>
              <MenuItem value="QUALIFIED">Qualified</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl className={classes.formControl} fullWidth>
            <InputLabel shrink id="select-time-range-label">
              Creation Date
            </InputLabel>
            <Select
              labelId="creation-date-select-label"
              id="creation-date-select"
              value={props.timeRange}
              onChange={props.actions.handleCreationDateChange}
            >
              <MenuItem value="ALL">N/A</MenuItem>
              <MenuItem value="WEEK">This Week</MenuItem>
              <MenuItem value="MONTH">This Month</MenuItem>
              <MenuItem value="YEAR">This Year</MenuItem>
              <MenuItem value="CUSTOM">Custom</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {props.timeRange === 'CUSTOM' && (
          <Grid item xs={3}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                id="start-date-picker-dialog"
                label="From"
                format="MM/dd/yyyy"
                value={props.fromDate}
                onChange={props.actions.handleFromDateChange}
                maxDate={props.toDate}
                KeyboardButtonProps={{
                  'aria-label': 'change start date',
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        )}
        {props.timeRange === 'CUSTOM' && (
          <Grid item xs={3}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                color="secondary"
                id="end-date-picker-dialog"
                label="To"
                format="MM/dd/yyyy"
                value={props.toDate}
                maxDate={new Date()}
                onChange={props.actions.handleToDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change end date',
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        )}
      </Grid>
    </div>
  );
}
