import React from "react";
import { forwardRef } from "react";
import _ from "lodash";
import { common, green, red } from "@material-ui/core/colors";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Button from "@material-ui/core/Button";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import CircularProgress from "@material-ui/core/CircularProgress";
import Clear from "@material-ui/icons/Clear";
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import InfoIcon from "@material-ui/icons/Info";
import LastPage from "@material-ui/icons/LastPage";
import MaterialTable from "material-table";
import moment from "moment";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <Delete {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => (
    <FirstPage {...props} style={{ color: common["white"] }} ref={ref} />
  )),
  LastPage: forwardRef((props, ref) => (
    <LastPage {...props} style={{ color: common["white"] }} ref={ref} />
  )),
  NextPage: forwardRef((props, ref) => (
    <ChevronRight {...props} style={{ color: common["white"] }} ref={ref} />
  )),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} style={{ color: common["white"] }} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => (
    <Clear {...props} style={{ color: common["white"] }} ref={ref} />
  )),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const useStyles = makeStyles(theme => ({
  buttonGreen: {
    color: green[500]
  },
  buttonRed: {
    color: red[500]
  },
  icon: {
    color: theme.palette.common.white
  },
  link: {
    color: theme.palette.secondary.main
  },
  loadingOverlay: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: fade(theme.palette.common.black, 0.8)
  },
  margin: {
    marginRight: theme.spacing(2)
  }
}));

const headerStyle = {
  backgroundColor: "#171415"
};

const leadTypes = {
  COLD: "Cold",
  WARM: "Warm",
  QUALIFIED: "Qualified"
};

export default function EmailableRecordsTable(props) {
  const classes = useStyles();
  const history = useHistory();

  const getActions = () => {
    return [
      {
        tooltip: "View Business Info",
        icon: () => <InfoIcon className={classes.infoIcon} />,
        onClick: (event, rowData) => {
          history.push(`/staff/leads/${rowData.id}`);
        }
      }
    ];
  };

  const getTableTitle = recordCount => {
    return `${recordCount} Records Selected`;
  };

  const getColumns = userType => {
    const columns = [
      {
        headerStyle,
        title: "Business",
        field: "name"
      },
      {
        title: "Lead Type",
        field: "leadType",
        lookup: leadTypes,
        initialEditValue: "COLD",
        render: rowData => <span>{leadTypes[rowData.leadType]}</span>
      },
      {
        headerStyle,
        title: "Contact Date",
        field: "lastContacted",
        type: "datetime",
        editable: "never",
        render: rowData => {
          if (rowData.lastContacted) {
            return (
              <span>{`${moment(rowData.lastContacted).format(
                "DD MMM YYYY"
              )}`}</span>
            );
          } else {
            return <span>Never</span>;
          }
        }
      },
      {
        title: "Days Uncontacted",
        field: "contactDiff",
        type: "numeric",
        defaultSort: "desc"
      },
      {
        title: "Sales Person",
        field: "salesPerson"
      },
      {
        headerStyle,
        title: "",
        field: "action",
        searchable: false,
        export: false,
        sorting: false,
        render: rowData => {
          return (
            <Button
              aria-label="info"
              color="primary"
              variant="contained"
              onClick={() =>
                history.push(`/staff/email-campaigns/${rowData.id}`)
              }
            >
              View Info
            </Button>
          );
        }
      }
    ];

    return columns;
  };

  const getShortenedString = (string, maxLength = 30) => {
    if (string.length > maxLength) {
      return _.truncate(string, { length: maxLength });
    }
    return string;
  };

  const getFormattedData = (data, recordsSelected, users) => {
    const recordsSelectedObject = _.fromPairs(
      recordsSelected.map(record => [record.id, true])
    );

    return _.toPairs(data)
      .filter(
        ([id, info]) =>
          !info.isDeleted && info.contactCount === props.contactCount
      )
      .map(([id, info]) => {
        return {
          id,
          name: getShortenedString(info.name),
          leadType: info.leadType,
          lastContacted: info.lastContacted,
          contactDiff: info.lastContacted
            ? moment().diff(moment(info.lastContacted), "days")
            : "",
          salesPerson: users[info.agentId]
            ? `${users[info.agentId].name} ${users[info.agentId].surname}`
            : "",
          tableData: {
            checked: recordsSelectedObject[id]
          }
        };
      });
  };

  const formattedData = getFormattedData(
    props.data,
    props.recordsSelected,
    props.users
  );

  return (
    <React.Fragment>
      <MaterialTable
        actions={getActions(props.section)}
        isLoading={props.isLoading}
        icons={tableIcons}
        columns={getColumns(props.userType)}
        data={formattedData}
        components={{
          OverlayLoading: props => (
            <div className={classes.loadingOverlay}>
              <CircularProgress color="primary" size={32} />
            </div>
          )
        }}
        options={{
          headerStyle,
          showTextRowsSelected: false,
          pageSizeOptions: [],
          grouping: false,
          draggable: false,
          selection: true
        }}
        title={getTableTitle(props.recordsSelected.length)}
        localization={{
          header: {
            actions: ""
          },
          body: {
            emptyDataSourceMessage: "No records to display"
          }
        }}
        onSelectionChange={rows => props.actions.handleSelectionChange(rows)}
      />
    </React.Fragment>
  );
}
