import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ContactsTable from "./components/ContactsTable";
import Container from "@material-ui/core/Container";
import FiltersBar from "./components/FiltersBar";
import LeadEntryBar from "./components/LeadEntryBar";

const useStyles = makeStyles(theme => ({
  innerContent: {
    padding: theme.spacing(3)
  }
}));

const initialState = {
  showDeleted: false
};

export default function Contacts(props) {
  const classes = useStyles();
  const [state, setState] = React.useState(initialState);

  const toggleShowDeleted = () => {
    setState({
      ...state,
      showDeleted: !state.showDeleted
    });
  };

  return (
    <Container className={classes.innerContent}>
      <LeadEntryBar
        actions={{
          createNewContact: (name, surname, jobTitle, email, phoneNumber) =>
            props.actions.createNewContact(
              name,
              surname,
              jobTitle,
              email,
              phoneNumber
            )
        }}
      />
      <FiltersBar
        showDeleted={state.showDeleted}
        actions={{
          toggleShowDeleted: () => toggleShowDeleted()
        }}
      />
      <ContactsTable
        data={props.contacts}
        isLoading={props.isLoading}
        showDeleted={state.showDeleted}
        actions={{
          makeContactPrimary: (id, otherContacts) =>
            props.actions.makeContactPrimary(id, otherContacts),
          restoreContact: id => props.actions.restoreContact(id),
          deleteContact: id => props.actions.deleteContact(id),
          editContactDetails: (id, newDetails) =>
            props.actions.editContactDetails(id, newDetails)
        }}
      />
    </Container>
  );
}
