import React from "react";
import _ from "lodash";
import { grey } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { useParams, useHistory } from "react-router-dom";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import BasicInfo from "./components/BasicInfo";
import Button from "@material-ui/core/Button";
import Contacts from "./components/Contacts";
import Container from "@material-ui/core/Container";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";

const sections = {
  BASIC_INFO: "Basic Info",
  CONTACTS: "Contacts"
};

const useStyles = makeStyles(theme => ({
  header: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(4)
  },
  headingSection: {
    color: grey[700]
  },
  headingTitle: {
    color: theme.palette.common.white,
    marginBottom: theme.spacing(2)
  },
  innerContent: {
    padding: theme.spacing(3)
  },
  tab: {
    color: theme.palette.secondary.light
  }
}));

const initialState = {
  firstPaint: false,
  isLoading: false,
  contacts: [],
  activeSection: {
    name: "Basic Info",
    value: "BASIC_INFO"
  }
};

export default function BusinessInfo(props) {
  const classes = useStyles();
  const [state, setState] = React.useState(initialState);
  let { businessId } = useParams();
  let history = useHistory();

  React.useEffect(() => {
    if (!state.firstPaint) {
      props.actions.loadBusinessInfo(businessId);
      setState({
        ...state,
        firstPaint: true
      });
    }
  }, [state, props, businessId]);

  async function updateContacts(activeSection) {
    let contacts = await props.actions.loadBusinessContacts(businessId);
    setState({
      ...state,
      contacts,
      activeSection,
      isLoading: false
    });
  }

  const handleTabChange = (event, newActiveSection) => {
    let isLoading = false;
    let activeSection = {
      name: sections[newActiveSection],
      value: newActiveSection
    };

    if (newActiveSection === "CONTACTS") {
      isLoading = true;
      updateContacts(activeSection);
    }

    setState({
      ...state,
      isLoading,
      activeSection
    });
  };

  const getTabContent = userType => {
    switch (state.activeSection.value) {
      case "BASIC_INFO":
        return (
          <BasicInfo
            isLoading={props.isLoading}
            name={props.businessInfo.name}
            websiteUrl={props.businessInfo.websiteUrl}
            status={props.businessInfo.status}
            lastContacted={props.businessInfo.lastContacted}
            creationDate={props.businessInfo.creationDate}
            leadInfo={props.businessInfo.leadInfo}
            userType={userType}
            actions={{
              editBusinessName: businessName =>
                props.actions.editBusinessName(businessId, businessName),
              editWebsiteUrl: websiteUrl =>
                props.actions.editWebsiteUrl(businessId, websiteUrl),
              editLeadType: leadType =>
                props.actions.editLeadType(businessId, leadType)
            }}
          />
        );
      case "CONTACTS":
        return (
          <Contacts
            contacts={state.contacts}
            isLoading={state.isLoading}
            actions={{
              makeContactPrimary: (id, otherContacts) => {
                setState({
                  ...state,
                  isLoading: true
                });
                props.actions.makeContactPrimary(id, otherContacts).then(() =>
                  updateContacts({
                    name: sections["CONTACTS"],
                    value: "CONTACTS"
                  })
                );
              },
              restoreContact: id => {
                setState({
                  ...state,
                  isLoading: true
                });
                props.actions.restoreContact(id).then(() =>
                  updateContacts({
                    name: sections["CONTACTS"],
                    value: "CONTACTS"
                  })
                );
              },
              deleteContact: id => {
                setState({
                  ...state,
                  isLoading: true
                });
                props.actions.deleteContact(id).then(() =>
                  updateContacts({
                    name: sections["CONTACTS"],
                    value: "CONTACTS"
                  })
                );
              },
              createNewContact: (
                name,
                surname,
                jobTitle,
                email,
                phoneNumber
              ) => {
                setState({
                  ...state,
                  isLoading: true
                });
                props.actions
                  .createNewContact(
                    businessId,
                    props.businessInfo.name,
                    name,
                    surname,
                    jobTitle,
                    email,
                    phoneNumber
                  )
                  .then(() =>
                    updateContacts({
                      name: sections["CONTACTS"],
                      value: "CONTACTS"
                    })
                  );
              },
              editContactDetails: (id, newDetails) => {
                setState({
                  ...state,
                  isLoading: true
                });
                props.actions.editContactDetails(id, newDetails).then(() =>
                  updateContacts({
                    name: sections["CONTACTS"],
                    value: "CONTACTS"
                  })
                );
              }
            }}
          />
        );
      default:
        return <div />;
    }
  };

  return (
    <div>
      <div className={classes.header}>
        <Typography
          className={classes.headingSection}
          component="h1"
          variant="subtitle1"
        >
          Business Info
        </Typography>
        <Typography
          className={classes.headingTitle}
          component="h2"
          variant="h3"
        >
          {props.businessInfo.name}
        </Typography>
        <Button
          color="secondary"
          startIcon={<ArrowBackIosIcon />}
          onClick={history.goBack}
        >
          Back
        </Button>
      </div>
      <Tabs
        aria-label="leads tabs"
        textColor="secondary"
        value={state.activeSection.value}
        className={classes.tab}
        onChange={handleTabChange}
      >
        {_.toPairs(sections).map(([value, name]) => (
          <Tab key={value} label={name} value={value} className={classes.tab} />
        ))}
      </Tabs>
      <Container className={classes.innerContent}>
        {getTabContent(props.userType)}
      </Container>
    </div>
  );
}
