import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  active: {
    transition: "0.25s !important",
    "&:hover": {
      cursor: "pointer"
    },
    color: theme.palette.secondary.main,
    textDecoration: "none",
    fontWeight: "bold",
    margin: `0 ${theme.spacing(2)}px`
  },
  inactive: {
    transition: "0.25s !important",
    "&:hover": {
      cursor: "pointer",
      color: theme.palette.primary.contrastText
    },
    textDecoration: "none",
    color: theme.palette.secondary.light,
    margin: `0 ${theme.spacing(2)}px`
  }
}));

export default function CustomAppBar(props) {
  const classes = useStyles();
  const { text, isActive, link, changeActiveLink } = props;

  return (
    <Typography
      variant="body1"
      component="a"
      className={isActive ? classes.active : classes.inactive}
      onClick={changeActiveLink(link)}
    >
      {text}
    </Typography>
  );
}
