import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import Container from "@material-ui/core/Container";
import CreateIcon from "@material-ui/icons/Create";
import Grid from "@material-ui/core/Grid";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  container: {
    padding: `0 ${theme.spacing(2)}px`
  },
  introText: {
    color: theme.palette.secondary.light
  },
  rootWrapper: {
    backgroundColor: theme.palette.common.white,
    padding: `${theme.spacing(20)}px 0`
  },
  sectionHeading: {
    color: theme.palette.primary.main
  },
  serviceIcon: {
    width: 64,
    height: 64,
    marginBottom: theme.spacing(2),
    color: theme.palette.secondary.main
  },
  serviceHeading: {
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main
  },
  serviceText: {
    color: theme.palette.secondary.light
  },
  serviceWrapper: {
    padding: theme.spacing(3),
    textAlign: "center"
  }
}));

export default function OurServices(props) {
  const classes = useStyles();

  return (
    <div className={classes.rootWrapper} id="our-services">
      <Container>
        <Grid
          container
          spacing={6}
          alignItems="center"
          justify="center"
          direction="column"
          className={classes.container}
        >
          <Grid item xs={12}>
            <Typography
              variant="h2"
              component="h2"
              align="center"
              className={classes.sectionHeading}
            >
              Our Services
            </Typography>
          </Grid>
          <Grid item xs={12} sm={9} md={6}>
            <Typography
              variant="body1"
              component="p"
              align="left"
              className={classes.introText}
            >
              Below are the various services we offer your business to help
              ensure a smooth transition into ecommerce:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={6} justify="center" alignItems="stretch">
              <Grid item xs={12} sm={6} md={4}>
                <div className={classes.serviceWrapper}>
                  <AssignmentTurnedInIcon className={classes.serviceIcon} />
                  <Typography
                    variant="h5"
                    component="h4"
                    align="center"
                    className={classes.serviceHeading}
                  >
                    Viability & Compliance
                  </Typography>
                  <Typography
                    variant="body2"
                    component="p"
                    align="left"
                    className={classes.serviceText}
                  >
                    We make sure your product is compliant with the laws and
                    standards of the target marketplace, and do research to help
                    us understand if your product is able to compete.
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <div className={classes.serviceWrapper}>
                  <PhotoCameraIcon className={classes.serviceIcon} />
                  <Typography
                    variant="h5"
                    component="h4"
                    align="center"
                    className={classes.serviceHeading}
                  >
                    Photography
                  </Typography>
                  <Typography
                    variant="body2"
                    component="p"
                    align="left"
                    className={classes.serviceText}
                  >
                    We enlist the services of a professional product
                    photographer to shoot crisp, studio-quality photos of your
                    product. High-quality photography is perhaps the most
                    important factor for success online.
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <div className={classes.serviceWrapper}>
                  <CreateIcon className={classes.serviceIcon} />
                  <Typography
                    variant="h5"
                    component="h4"
                    align="center"
                    className={classes.serviceHeading}
                  >
                    Copywriting
                  </Typography>
                  <Typography
                    variant="body2"
                    component="p"
                    align="left"
                    className={classes.serviceText}
                  >
                    Our copywriters not only use emotive language to entice the
                    shopper, but also do comprehensive keyword research to make
                    your product more discoverable online.
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <div className={classes.serviceWrapper}>
                  <LocalShippingIcon className={classes.serviceIcon} />
                  <Typography
                    variant="h5"
                    component="h4"
                    align="center"
                    className={classes.serviceHeading}
                  >
                    Logistics
                  </Typography>
                  <Typography
                    variant="body2"
                    component="p"
                    align="left"
                    className={classes.serviceText}
                  >
                    We deal with the couriers, make sure your units are packaged
                    according to various requirements, handle customs where
                    necessary, and track replenishment requirements.
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <div className={classes.serviceWrapper}>
                  <RecordVoiceOverIcon className={classes.serviceIcon} />
                  <Typography
                    variant="h5"
                    component="h4"
                    align="center"
                    className={classes.serviceHeading}
                  >
                    Marketing
                  </Typography>
                  <Typography
                    variant="body2"
                    component="p"
                    align="left"
                    className={classes.serviceText}
                  >
                    We have partnered with an experienced marketing agency to
                    ensure that your product gets the attention it deserves.
                    Without marketing, your product would be lost in a sea of
                    competition.
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <div className={classes.serviceWrapper}>
                  <ThumbUpIcon className={classes.serviceIcon} />
                  <Typography
                    variant="h5"
                    component="h4"
                    align="center"
                    className={classes.serviceHeading}
                  >
                    Customer Service
                  </Typography>
                  <Typography
                    variant="body2"
                    component="p"
                    align="left"
                    className={classes.serviceText}
                  >
                    It's important for your brand that shoppers have a positive
                    experience when buying your product. We make sure to respond
                    to any queries or issues as soon as possible to keep your
                    customers happy.
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
