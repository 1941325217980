import React from "react";
import { forwardRef } from "react";
import _ from "lodash";
import { common, green, red } from "@material-ui/core/colors";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import CircularProgress from "@material-ui/core/CircularProgress";
import Clear from "@material-ui/icons/Clear";
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import MailIcon from "@material-ui/icons/Mail";
import MaterialTable from "material-table";
import moment from "moment";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import Tooltip from "@material-ui/core/Tooltip";
import ViewColumn from "@material-ui/icons/ViewColumn";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <Delete {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => (
    <SaveAlt className={useStyles().icon} {...props} ref={ref} />
  )),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => (
    <FirstPage {...props} style={{ color: common["white"] }} ref={ref} />
  )),
  LastPage: forwardRef((props, ref) => (
    <LastPage {...props} style={{ color: common["white"] }} ref={ref} />
  )),
  NextPage: forwardRef((props, ref) => (
    <ChevronRight {...props} style={{ color: common["white"] }} ref={ref} />
  )),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} style={{ color: common["white"] }} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => (
    <Clear {...props} style={{ color: common["white"] }} ref={ref} />
  )),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const useStyles = makeStyles(theme => ({
  buttonGreen: {
    color: green[500]
  },
  buttonRed: {
    color: red[500]
  },
  chipGreen: {
    backgroundColor: green[500]
  },
  chipRed: {
    backgroundColor: red[500]
  },
  icon: {
    color: theme.palette.common.white
  },
  infoIcon: {
    color: theme.palette.secondary.main
  },
  link: {
    color: theme.palette.secondary.main
  },
  loadingOverlay: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: fade(theme.palette.common.black, 0.8)
  },
  margin: {
    marginRight: theme.spacing(2)
  }
}));

const headerStyle = {
  backgroundColor: "#171415"
};

export default function SalesTable(props) {
  const classes = useStyles();
  const history = useHistory();

  const getTableTitle = (section, leadCount) => {
    switch (section) {
      case "LEADS":
        return `${leadCount} Leads`;
      case "PROSPECTS":
        return `${leadCount} Prospects`;
      case "RESOLVED":
        return `${leadCount} Resolved`;
      default:
        return "";
    }
  };

  const getColumns = userType => {
    const columns = [
      {
        headerStyle,
        title: "Business",
        field: "name"
      },
      {
        headerStyle,
        title: "Website",
        field: "websiteUrl",
        render: rowData =>
          rowData.websiteUrl === "Unknown" || rowData.websiteUrl === null ? (
            <span>No website provided</span>
          ) : (
            <a
              href={rowData.websiteUrl}
              rel="noopener noreferrer"
              target="_blank"
              className={classes.link}
            >
              Visit website
            </a>
          )
      },
      {
        headerStyle,
        title: "Last Contacted",
        field: "lastContacted",
        type: "datetime",
        editable: "never",
        defaultSort: "asc",
        render: rowData => {
          return (
            <span>{`${moment(rowData.lastContacted).format(
              "DD MMM YYYY"
            )}`}</span>
          );
        }
      }
    ];

    if (userType !== "SALES_AGENT") {
      columns.push({
        headerStyle,
        title: "Sales Person",
        editable: "never",
        field: "salesPerson"
      });
    }

    if (props.section === "LEAD") {
      columns.push({
        headerStyle,
        title: "Response",
        field: "response",
        editable: "never",
        sorting: false,
        render: rowData => {
          return (
            <ButtonGroup
              variant="contained"
              color="primary"
              aria-label="contained primary button group"
            >
              <Tooltip title="Lead is interested">
                <Button className={classes.buttonGreen}>
                  <ThumbUpIcon
                    onClick={() => props.actions.makeLeadProspect(rowData.id)}
                  />
                </Button>
              </Tooltip>
              <Tooltip title="Lead is not interested">
                <Button className={classes.buttonRed}>
                  <ThumbDownIcon
                    onClick={() => props.actions.makeLeadLost(rowData.id)}
                  />
                </Button>
              </Tooltip>
            </ButtonGroup>
          );
        }
      });
    } else if (props.section === "PROSPECT") {
      columns.push({
        headerStyle,
        title: "Update",
        field: "update",
        editable: "never",
        sorting: false,
        render: rowData => {
          return (
            <ButtonGroup
              variant="contained"
              color="primary"
              aria-label="contained primary button group"
            >
              <Tooltip title="Prospect has paid">
                <Button className={classes.buttonGreen}>
                  <AttachMoneyIcon
                    onClick={() => props.actions.makeLeadClient(rowData.id)}
                  />
                </Button>
              </Tooltip>
              <Tooltip title="Log new contact date">
                <Button>
                  <MailIcon
                    onClick={() => props.actions.logNewContactDate(rowData.id)}
                  />
                </Button>
              </Tooltip>
              <Tooltip title="Prospect no longer interested">
                <Button className={classes.buttonRed}>
                  <ThumbDownIcon
                    onClick={() => props.actions.makeLeadLost(rowData.id)}
                  />
                </Button>
              </Tooltip>
            </ButtonGroup>
          );
        }
      });
    }

    columns.push({
      headerStyle,
      title: "",
      field: "action",
      searchable: false,
      export: false,
      sorting: false,
      render: rowData => {
        return (
          <Button
            aria-label="info"
            color="primary"
            variant="contained"
            onClick={() => history.push(`/staff/sales/${rowData.id}`)}
          >
            View Info
          </Button>
        );
      }
    });

    return columns;
  };

  const getShortenedString = (string, maxLength = 30) => {
    if (string.length > maxLength) {
      return _.truncate(string, { length: maxLength });
    }
    return string;
  };

  const getFormattedData = data => {
    return _.toPairs(data)
      .filter(([id, info]) => !info.isDeleted)
      .filter(([id, info]) => {
        if (props.section !== "LOST" && info.salesStatus === props.section) {
          return true;
        } else if (props.section === "LOST" && info.isLost) {
          return true;
        }
        return false;
      })
      .map(([id, info]) => {
        return {
          id,
          salesPerson:
            props.users[info.agentId] &&
            `${props.users[info.agentId].name} ${props.users[info.agentId].surname}`,
          lastContacted: info.lastContacted,
          name: getShortenedString(info.name),
          status: info.status,
          type: info.type,
          websiteUrl: info.websiteUrl.includes("http")
            ? info.websiteUrl
            : `https://${info.websiteUrl}`,
          salesStatus: info.salesStatus,
          isLost: info.isLost
        };
      });
  };

  const formattedData = getFormattedData(props.data);

  return (
    <React.Fragment>
      <MaterialTable
        isLoading={props.isLoading}
        icons={tableIcons}
        columns={getColumns(props.userType)}
        data={formattedData}
        components={{
          OverlayLoading: props => (
            <div className={classes.loadingOverlay}>
              <CircularProgress color="primary" size={32} />
            </div>
          )
        }}
        options={{
          headerStyle,
          pageSizeOptions: [],
          grouping: false,
          draggable: false,
          exportButton: true,
          exportAllData: true,
          exportFileName: "Sales Data"
        }}
        title={getTableTitle(props.section, formattedData.length)}
        localization={{
          header: {
            actions: ""
          }
        }}
      />
    </React.Fragment>
  );
}
