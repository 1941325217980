import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import AppBarLink from "./components/AppBarLink";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import MenuIcon from "@material-ui/icons/Menu";
import MobileMenu from "./components/MobileMenu";
import Scrollspy from "react-scrollspy";
import Slide from "@material-ui/core/Slide";
import Toolbar from "@material-ui/core/Toolbar";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Zoom from "@material-ui/core/Zoom";

import logo from "./images/logo.png";

const useStyles = makeStyles(theme => ({
  appBar: {
    backgroundColor: theme.palette.primary.dark
  },
  logo: {
    cursor: "pointer",
    textAlign: "center"
  },
  root: {
    flexGrow: 1,
    margin: 0
  },
  scrollToTopButton: {
    position: "fixed",
    bottom: theme.spacing(4),
    right: theme.spacing(4)
  },
  separator: {
    flexGrow: 1
  }
}));

export default function CustomAppBar() {
  const classes = useStyles();
  const trigger = useScrollTrigger();
  let history = useHistory();

  const [state, setState] = React.useState({
    activeLink: "home",
    isDrawerOpen: false
  });

  const changeActiveLink = (activeLink, mobile) => event => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      `#${activeLink}`
    );

    if (anchor) {
      anchor.scrollIntoView({
        behavior: mobile ? "auto" : "smooth",
        block: "start"
      });
    }

    setState({ ...state, isDrawerOpen: false });
  };

  const toggleDrawer = isOpen => {
    setState({ ...state, isDrawerOpen: isOpen });
  };

  const updateNavBar = activeLink => {
    setState({ ...state, activeLink });
  };

  return (
    <div className={classes.root}>
      <Slide appear={false} direction="down" in={!trigger}>
        <AppBar className={classes.appBar} position="fixed">
          <Toolbar>
            <span
              className={classes.logo}
              onClick={changeActiveLink("home", false)}
            >
              <img src={logo} alt="Logo" />
            </span>
            <div className={classes.separator} />
            <Hidden smDown>
              <Scrollspy
                items={[
                  "home",
                  "our-story",
                  "our-process",
                  "our-services",
                  "contact-us"
                ]}
                offset={-100}
                onUpdate={component => updateNavBar(component.id)}
              >
                <AppBarLink
                  text="Home"
                  color="inherit"
                  link="home"
                  isActive={state.activeLink === "home"}
                  changeActiveLink={() => changeActiveLink("home", false)}
                />
                <AppBarLink
                  text="Our Story"
                  color="inherit"
                  link="our-story"
                  isActive={state.activeLink === "our-story"}
                  changeActiveLink={() => changeActiveLink("our-story", false)}
                />
                <AppBarLink
                  text="Our Process"
                  color="inherit"
                  link="our-process"
                  isActive={state.activeLink === "our-process"}
                  changeActiveLink={() =>
                    changeActiveLink("our-process", false)
                  }
                />
                <AppBarLink
                  text="Our Services"
                  color="inherit"
                  link="our-services"
                  isActive={state.activeLink === "our-services"}
                  changeActiveLink={() =>
                    changeActiveLink("our-services", false)
                  }
                />
                <AppBarLink
                  text="Contact Us"
                  color="inherit"
                  link="contact-us"
                  isActive={state.activeLink === "contact-us"}
                  changeActiveLink={() => changeActiveLink("contact-us", false)}
                />
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => history.push("/staff")}
                >
                  Log in
                </Button>
              </Scrollspy>
            </Hidden>
            <Hidden mdUp>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => toggleDrawer(true)}
              >
                <MenuIcon />
              </IconButton>
            </Hidden>
          </Toolbar>
        </AppBar>
      </Slide>
      <MobileMenu
        isDrawerOpen={state.isDrawerOpen}
        toggleDrawer={toggleDrawer}
        changeActiveLink={changeActiveLink}
      />
      <Zoom in={trigger} className={classes.scrollToTopButton}>
        <Fab
          color="secondary"
          aria-label="scroll back to top"
          onClick={changeActiveLink("home", false)}
        >
          <KeyboardArrowUpIcon />
        </Fab>
      </Zoom>
    </div>
  );
}
