import React from "react";
import _ from "lodash";
import { grey } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { Route, Switch, useLocation } from "react-router-dom";
import BusinessInfo from "../BusinessInfo";
import Container from "@material-ui/core/Container";
import FiltersBar from "./components/FiltersBar";
import SalesTable from "./components/SalesTable";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";

const sections = {
  LEAD: "Leads",
  PROSPECT: "Prospects",
  CLIENT: "Clients",
  LOST: "Lost"
};

const useStyles = makeStyles(theme => ({
  header: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(4)
  },
  headingSection: {
    color: grey[700]
  },
  headingTitle: {
    color: theme.palette.common.white,
    marginBottom: theme.spacing(2)
  },
  innerContent: {
    padding: theme.spacing(3)
  },
  tab: {
    color: theme.palette.secondary.light
  }
}));

const initialState = {
  activeSection: {
    name: "Leads",
    value: "LEAD"
  },
  salesPerson: "ALL",
  firstPaint: false
};

export default function Sales(props) {
  const classes = useStyles();
  const [state, setState] = React.useState(initialState);
  let location = useLocation();

  React.useEffect(() => {
    if (
      !state.firstPaint &&
      props.userType !== "SALES_AGENT" &&
      location.pathname === "/staff/sales"
    ) {
      props.actions.loadSalesRecords(
        state.activeSection.value,
        state.salesPerson,
        state.fromDate,
        state.toDate
      );
      setState({
        ...state,
        firstPaint: true
      });
    }
    if (
      !state.firstPaint &&
      props.userType === "SALES_AGENT" &&
      location.pathname === "/staff/sales"
    ) {
      props.actions.loadSalesRecords(
        state.activeSection.value,
        props.userId,
        state.fromDate,
        state.toDate
      );
      setState({
        ...state,
        firstPaint: true,
        salesPerson: props.userId
      });
    }
  }, [state, props, location]);

  const handleTabChange = (event, newActiveSection) => {
    if (props.userType !== "SALES_AGENT") {
      props.actions.loadSalesRecords(
        newActiveSection,
        state.salesPerson,
        state.fromDate,
        state.toDate
      );
      setState({
        ...state,
        activeSection: {
          name: sections[newActiveSection],
          value: newActiveSection
        }
      });
    } else {
      props.actions.loadSalesRecords(
        newActiveSection,
        props.userId,
        state.fromDate,
        state.toDate
      );
      setState({
        ...state,
        activeSection: {
          name: sections[newActiveSection],
          value: newActiveSection
        }
      });
    }
  };

  const handleSalesPersonChange = newValue => {
    props.actions.loadSalesRecords(
      state.activeSection.value,
      newValue,
      state.fromDate,
      state.toDate
    );

    setState({
      ...state,
      salesPerson: newValue
    });
  };

  return (
    <Switch>
      <Route exact path="/staff/sales">
        <div className={classes.header}>
          <Typography
            className={classes.headingSection}
            component="h1"
            variant="subtitle1"
          >
            {state.activeSection.name}
          </Typography>
          <Typography
            className={classes.headingTitle}
            component="h2"
            variant="h3"
          >
            Sales Management
          </Typography>
        </div>
        <Tabs
          aria-label="sales tabs"
          textColor="secondary"
          value={state.activeSection.value}
          className={classes.tab}
          onChange={handleTabChange}
        >
          {_.toPairs(sections).map(([value, name]) => (
            <Tab
              key={value}
              label={name}
              value={value}
              className={classes.tab}
            />
          ))}
        </Tabs>
        <Container className={classes.innerContent}>
          {props.userType !== "SALES_AGENT" && (
            <FiltersBar
              salesPerson={state.salesPerson}
              users={props.users}
              actions={{
                handleSalesPersonChange: event =>
                  handleSalesPersonChange(event.target.value)
              }}
            />
          )}
          <SalesTable
            isLoading={props.isLoading}
            userType={props.userType}
            users={props.users}
            section={state.activeSection.value}
            data={props.salesRecords}
            actions={{
              makeLeadProspect: id => props.actions.makeLeadProspect(id),
              makeLeadLost: id => props.actions.makeLeadLost(id),
              makeLeadClient: id => props.actions.makeLeadClient(id),
              logNewContactDate: id => props.actions.logNewContactDate(id)
            }}
          />
        </Container>
      </Route>
      <Route path="/staff/sales/:businessId">
        <BusinessInfo
          userType={props.userType}
          isLoading={props.isLoading}
          businessInfo={props.businessInfo}
          actions={{
            restoreContact: id => props.actions.restoreContact(id),
            deleteContact: id => props.actions.deleteContact(id),
            loadBusinessInfo: id => props.actions.loadBusinessInfo(id),
            loadBusinessContacts: id => props.actions.loadBusinessContacts(id),
            validateBusinessName: name =>
              props.actions.validateBusinessName(name),
            validateBusinessWebsite: websiteUrl =>
              props.actions.validateBusinessWebsite(websiteUrl),
            editBusinessName: (id, businessName) =>
              props.actions.editBusinessName(id, businessName),
            editWebsiteUrl: (id, websiteUrl) =>
              props.actions.editWebsiteUrl(id, websiteUrl),
            editLeadType: (id, leadType) =>
              props.actions.editLeadType(id, leadType),
            makeContactPrimary: (id, otherContacts) =>
              props.actions.makeContactPrimary(id, otherContacts),
            createNewContact: (
              businessId,
              businessName,
              name,
              surname,
              jobTitle,
              email,
              phoneNumber
            ) =>
              props.actions.createNewContact(
                businessId,
                businessName,
                name,
                surname,
                jobTitle,
                email,
                phoneNumber
              ),
            editContactDetails: (id, newDetails) =>
              props.actions.editContactDetails(id, newDetails)
          }}
        />
      </Route>
    </Switch>
  );
}
