import React from 'react';
import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import DashboardIcon from '@material-ui/icons/Dashboard';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MailIcon from '@material-ui/icons/Mail';

const drawerItems = [
  {
    icon: <DashboardIcon />,
    name: 'Dashboard',
    value: 'dashboard',
  },
  {
    icon: <AttachMoneyIcon />,
    name: 'Sales',
    value: 'sales',
  },
  {
    icon: <MailIcon />,
    name: 'Email',
    value: 'email-campaigns',
  },
  {
    icon: <ContactMailIcon />,
    name: 'Leads',
    value: 'leads',
  },
];

const useStyles = makeStyles((theme) => ({
  listItemActive: {
    transition: 'all 0.25s ease',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
  },
  listItemInactive: {
    backgroundColor: 'inherit',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: grey[900],
    },
  },
  listItemTextActive: {
    color: theme.palette.common.black,
  },
  listItemTextInactive: {
    color: theme.palette.common.white,
  },
}));

export default function DrawerMenu(props) {
  const classes = useStyles();

  const getDrawerItems = (userType) => {
    switch (userType) {
      case 'ADMIN':
      case 'SALES_MANAGER':
        return [drawerItems[0], drawerItems[2], drawerItems[3]];
      case 'SALES_AGENT':
        return [drawerItems[0], drawerItems[3]];
      case 'LEADS_AGENT':
      case 'LEADS_MANAGER':
        return [drawerItems[0], drawerItems[3]];
      default:
        return [];
    }
  };

  return (
    <List component="nav">
      {getDrawerItems(props.userType).map((item) => (
        <ListItem
          key={item.value}
          onClick={props.actions.handleSectionChange(item.value)}
          className={
            item.value === props.activeSection
              ? classes.listItemActive
              : classes.listItemInactive
          }
        >
          <ListItemIcon
            className={
              item.value === props.activeSection
                ? classes.listItemTextActive
                : classes.listItemTextInactive
            }
          >
            {item.icon}
          </ListItemIcon>
          <ListItemText
            className={
              item.value === props.activeSection
                ? classes.listItemTextActive
                : classes.listItemTextInactive
            }
            primary={item.name}
          />
        </ListItem>
      ))}
    </List>
  );
}
