import React from "react";
import { grey } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Toolbar from "@material-ui/core/Toolbar";

import logo from "./images/logo.png";

const useStyles = makeStyles(theme => ({
  appBar: {
    backgroundColor: theme.palette.primary.dark,
    zIndex: theme.zIndex.drawer + 1
  },
  button: {
    margin: theme.spacing(1)
  },
  logo: {
    cursor: "pointer",
    textAlign: "center"
  },
  separator: {
    flexGrow: 1
  },
  version: {
    color: grey[700],
    marginRight: theme.spacing(2)
  }
}));

export default function PortalAppBar(props) {
  const classes = useStyles();
  let history = useHistory();

  return (
    <AppBar className={classes.appBar} position="fixed">
      <Toolbar>
        <span
          className={classes.logo}
          onClick={() => history.push("/staff/dashboard")}
        >
          <img src={logo} alt="Logo" />
        </span>
        <div className={classes.separator} />
        <span className={classes.version}>{`Version ${props.version}`}</span>
        <Button
          color="secondary"
          className={classes.button}
          startIcon={<ExitToAppIcon />}
          disabled={props.isLoading}
          onClick={props.actions.handleUserSignOut()}
        >
          {props.isLoading ? (
            <CircularProgress size={24} />
          ) : (
            <span>Log out</span>
          )}
        </Button>
      </Toolbar>
    </AppBar>
  );
}
