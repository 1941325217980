import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import LoginPage from "./components/LoginPage";
import StaffPortal from "./components/StaffPortal";
import Website from "./components/Website";

export default function App(props) {
  return (
    <Router>
      <Switch>
        <Route path="/login">
          <LoginPage />
        </Route>
        <Route path="/staff">
          <StaffPortal />
        </Route>
        <Route path="/">
          <Website />
        </Route>
      </Switch>
    </Router>
  );
}
