import React from "react";
import _ from "lodash";
import { grey } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import EditIcon from "@material-ui/icons/Edit";
import EditInfoDialog from "./components/EditInfoDialog";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import moment from "moment";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  dialogControl: {
    color: theme.palette.primary.main
  },
  link: {
    color: theme.palette.secondary.main
  },
  loadingWrapper: {
    textAlign: "center",
    padding: theme.spacing(6)
  },
  paper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  subtitle: {
    marginBottom: theme.spacing(2),
    color: theme.palette.common.white
  },
  textPrimary: {
    color: grey[700]
  },
  textSecondary: {
    color: theme.palette.common.white
  }
}));

const initialState = {
  isDialogOpen: false,
  editOptionSelected: "",
  businessName: "",
  websiteUrl: "",
  leadType: "",
  validation: {
    businessName: {
      error: false,
      message: ""
    },
    websiteUrl: {
      error: false,
      message: ""
    },
    leadType: {
      error: false,
      message: ""
    }
  }
};

export default function FiltersBar(props) {
  const classes = useStyles();
  const [state, setState] = React.useState(initialState);

  const openEditDialog = editOption => {
    setState({
      ...state,
      isDialogOpen: true,
      editOptionSelected: editOption,
      businessName: props.name,
      websiteUrl: props.websiteUrl,
      leadType: props.leadInfo.type
    });
  };

  const closeEditDialog = () => {
    setState({
      ...state,
      isDialogOpen: false,
      editOptionSelected: ""
    });
  };

  const handleDialogControlChange = (variable, value) => {
    let validation = {
      error: false,
      message: ""
    };

    if (value.length === 0) {
      validation = {
        error: true,
        message: "Required"
      };
    } else if (value.length > 300) {
      validation = {
        error: true,
        message: "Too many characters (max. 300)"
      };
    }

    setState({
      ...state,
      [variable]: value,
      validation: {
        ...state.validation,
        [variable]: validation
      }
    });
  };

  const getDialogControl = editOption => {
    switch (editOption) {
      case "businessName":
        return (
          <TextField
            autoFocus
            margin="dense"
            type="text"
            fullWidth
            value={state.businessName}
            helperText={state.validation.businessName.message}
            error={state.validation.businessName.error}
            onChange={event =>
              handleDialogControlChange(editOption, event.target.value)
            }
            inputProps={{
              className: classes.dialogControl
            }}
          />
        );
      case "websiteUrl":
        return (
          <TextField
            className={classes.dialogControl}
            autoFocus
            margin="dense"
            type="text"
            fullWidth
            value={state.websiteUrl}
            helperText={state.validation.websiteUrl.message}
            error={state.validation.websiteUrl.error}
            onChange={event =>
              handleDialogControlChange(editOption, event.target.value)
            }
            inputProps={{
              className: classes.dialogControl
            }}
          />
        );
      case "leadType":
        return (
          <Select
            className={classes.dialogControl}
            fullWidth
            value={state.leadType}
            onChange={event =>
              handleDialogControlChange(editOption, event.target.value)
            }
          >
            <MenuItem value="COLD">Cold</MenuItem>
            <MenuItem value="WARM">Warm</MenuItem>
            <MenuItem value="QUALIFIED">Qualified</MenuItem>
          </Select>
        );
      default:
        return <div />;
    }
  };

  const getDialogCopy = editOption => {
    switch (editOption) {
      case "businessName":
        return {
          title: "Edit Business Name",
          explanation: "Please enter a new business name below:"
        };
      case "websiteUrl":
        return {
          title: "Edit Website URL",
          explanation: "Please enter a new website URL below:"
        };
      case "leadType":
        return {
          title: "Edit Lead Type",
          explanation: "Please enter a new lead type below:"
        };
      default:
        return {
          title: "",
          explanation: ""
        };
    }
  };

  const checkIfErrorExists = () => {
    return (
      state.validation.businessName.error || state.validation.websiteUrl.error
    );
  };

  const handleEdit = editOption => {
    switch (editOption) {
      case "businessName":
        props.actions.editBusinessName(state.businessName);
        closeEditDialog();
        return;
      case "websiteUrl":
        props.actions.editWebsiteUrl(state.websiteUrl);
        closeEditDialog();
        return;
      case "leadType":
        props.actions.editLeadType(state.leadType);
        closeEditDialog();
        return;
      default:
        return;
    }
  };

  return (
    <div className={classes.root}>
      <Grid container alignItems="flex-start" spacing={3}>
        <Grid item xs={4}>
          <Paper className={classes.paper}>
            <Typography
              className={classes.subtitle}
              variant="h6"
              component="h3"
            >
              Details
            </Typography>
            <Divider />
            {props.isLoading ? (
              <div className={classes.loadingWrapper}>
                <CircularProgress size={32} color="secondary" />
              </div>
            ) : (
              <List aria-label="business details list">
                <ListItem>
                  <ListItemText
                    primaryTypographyProps={{
                      className: classes.textPrimary
                    }}
                    secondaryTypographyProps={{
                      className: classes.textSecondary
                    }}
                    primary="Name"
                    secondary={props.name}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="edit"
                      onClick={() => openEditDialog("businessName")}
                    >
                      <EditIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem>
                  <ListItemText
                    primaryTypographyProps={{
                      className: classes.textPrimary
                    }}
                    secondaryTypographyProps={{
                      className: classes.textSecondary
                    }}
                    primary="Website"
                    secondary={
                      <a
                        rel="noopener noreferrer"
                        target="_blank"
                        className={classes.link}
                        href={
                          props.websiteUrl.includes("http")
                            ? props.websiteUrl
                            : `https://${props.websiteUrl}`
                        }
                      >
                        {props.websiteUrl.includes("http")
                          ? props.websiteUrl
                          : `https://${props.websiteUrl}`}
                      </a>
                    }
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="edit"
                      onClick={() => openEditDialog("websiteUrl")}
                    >
                      <EditIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                {(props.userType === "ADMIN" ||
                  props.userType === "SALES_MANAGER" ||
                  props.userType === "SALES_AGENT") && (
                  <ListItem>
                    <ListItemText
                      primaryTypographyProps={{
                        className: classes.textPrimary
                      }}
                      secondaryTypographyProps={{
                        className: classes.textSecondary
                      }}
                      primary="Status"
                      secondary={
                        props.status === "DELETED" ? "Deleted" : "Active"
                      }
                    />
                  </ListItem>
                )}
                {(props.userType === "ADMIN" ||
                  props.userType === "SALES_MANAGER" ||
                  props.userType === "SALES_AGENT") && (
                  <ListItem>
                    <ListItemText
                      primaryTypographyProps={{
                        className: classes.textPrimary
                      }}
                      secondaryTypographyProps={{
                        className: classes.textSecondary
                      }}
                      primary="Last Contacted"
                      secondary={
                        props.lastContacted
                          ? `${moment(props.lastContacted).format(
                              "ddd, D MMMM YYYY"
                            )} (${moment(props.lastContacted).fromNow()})`
                          : "Never"
                      }
                    />
                  </ListItem>
                )}
              </List>
            )}
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper className={classes.paper}>
            <Typography
              className={classes.subtitle}
              variant="h6"
              component="h3"
            >
              Related Staff
            </Typography>
            <Divider />
            {props.isLoading ? (
              <div className={classes.loadingWrapper}>
                <CircularProgress size={32} color="secondary" />
              </div>
            ) : (
              <List aria-label="related staff list">
                <ListItem>
                  <ListItemText
                    primaryTypographyProps={{
                      className: classes.textPrimary
                    }}
                    secondaryTypographyProps={{
                      className: classes.textSecondary
                    }}
                    primary="Lead Sourcing Agent"
                    secondary={
                      props.leadInfo ? props.leadInfo.createdBy : "N/A"
                    }
                  />
                </ListItem>
                {(props.userType === "ADMIN" ||
                  props.userType === "SALES_MANAGER" ||
                  props.userType === "SALES_AGENT") &&
                  props.salesInfo && (
                    <ListItem>
                      <ListItemText
                        primaryTypographyProps={{
                          className: classes.textPrimary
                        }}
                        secondaryTypographyProps={{
                          className: classes.textSecondary
                        }}
                        primary="Sales Person"
                        secondary={props.salesInfo.agent}
                      />
                    </ListItem>
                  )}
                {(props.userType === "ADMIN" ||
                  props.userType === "SALES_MANAGER" ||
                  props.userType === "SALES_AGENT") &&
                  props.assessmentInfo && (
                    <ListItem>
                      <ListItemText
                        primaryTypographyProps={{
                          className: classes.textPrimary
                        }}
                        secondaryTypographyProps={{
                          className: classes.textSecondary
                        }}
                        primary="Assessment Agent"
                        secondary={props.assessmentInfo.agent}
                      />
                    </ListItem>
                  )}
                {(props.userType === "ADMIN" ||
                  props.userType === "SALES_MANAGER" ||
                  props.userType === "SALES_AGENT") &&
                  props.crmInfo && (
                    <ListItem>
                      <ListItemText
                        primaryTypographyProps={{
                          className: classes.textPrimary
                        }}
                        secondaryTypographyProps={{
                          className: classes.textSecondary
                        }}
                        primary="Account Manager"
                        secondary={props.crmInfo.agent}
                      />
                    </ListItem>
                  )}
              </List>
            )}
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper className={classes.paper}>
            <Typography
              className={classes.subtitle}
              variant="h6"
              component="h3"
            >
              Lead Info
            </Typography>
            <Divider />
            {props.isLoading ? (
              <div className={classes.loadingWrapper}>
                <CircularProgress size={32} color="secondary" />
              </div>
            ) : (
              <List aria-label="lead info list">
                <ListItem>
                  <ListItemText
                    primaryTypographyProps={{
                      className: classes.textPrimary
                    }}
                    secondaryTypographyProps={{
                      className: classes.textSecondary
                    }}
                    primary="Creation Date"
                    secondary={moment(props.creationDate).format("D MMMM YYYY")}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primaryTypographyProps={{
                      className: classes.textPrimary
                    }}
                    secondaryTypographyProps={{
                      className: classes.textSecondary
                    }}
                    primary="Sourced By"
                    secondary={props.leadInfo.createdBy}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primaryTypographyProps={{
                      className: classes.textPrimary
                    }}
                    secondaryTypographyProps={{
                      className: classes.textSecondary
                    }}
                    primary="Lead Type"
                    secondary={_.capitalize(props.leadInfo.type)}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="edit"
                      onClick={() => openEditDialog("leadType")}
                    >
                      <EditIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem>
                  <ListItemText
                    primaryTypographyProps={{
                      className: classes.textPrimary
                    }}
                    secondaryTypographyProps={{
                      className: classes.textSecondary
                    }}
                    primary="Review Status"
                    secondary={_.capitalize(props.leadInfo.status)}
                  />
                </ListItem>
              </List>
            )}
          </Paper>
        </Grid>
      </Grid>
      <EditInfoDialog
        isOpen={state.isDialogOpen}
        copy={getDialogCopy(state.editOptionSelected)}
        isLoading={props.isLoading}
        hasError={checkIfErrorExists()}
        actions={{
          handleClose: () => closeEditDialog(),
          handleEdit: () => handleEdit(state.editOptionSelected)
        }}
      >
        {getDialogControl(state.editOptionSelected)}
      </EditInfoDialog>
    </div>
  );
}
