import { createMuiTheme } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#A7A5A5",
      main: "#171415",
      dark: "#100E0E",
      contrastText: "#FCF9F8"
    },
    secondary: {
      light: "#584B53",
      main: "#F36438",
      dark: "#F1430E",
      contrastText: "#171415"
    },
    background: {
      default: "#212121"
    },
    common: {
      black: "#171415",
      white: "#FCF9F8"
    }
  },
  overrides: {
    MuiCheckbox: {
      root: {
        color: grey[700]
      }
    },
    MuiDialogActions: {
      root: {
        backgroundColor: "#A7A5A5"
      }
    },
    MuiDialogTitle: {
      root: {
        backgroundColor: "#584B53"
      }
    },
    MuiDivider: {
      root: {
        backgroundColor: "#0E0B0B"
      }
    },
    MuiFormControlLabel: {
      root: {
        color: "#FCF9F8"
      }
    },
    MuiFormLabel: {
      root: {
        color: grey[700],
        "&$focused": {
          color: grey[700]
        }
      }
    },
    MuiInput: {
      underline: {
        color: "#FCF9F8",
        "&::before": {
          borderBottomColor: "#FCF9F8"
        },
        "&:hover:not(.Mui-disabled):before": {
          borderBottomColor: "#FCF9F8"
        },
        "&::after": {
          borderBottomColor: "#FCF9F8"
        }
      }
    },
    MuiIconButton: {
      root: {
        color: "#FCF9F8"
      }
    },
    MuiInputLabel: {
      root: {
        color: grey[700],
        "&$focused": {
          color: grey[700]
        }
      }
    },
    MuiMenu: {
      paper: {
        backgroundColor: "#584B53"
      }
    },
    MuiPaper: {
      root: {
        backgroundColor: "#171415",
        color: "#FCF9F8"
      }
    },
    MuiPickersBasePicker: {
      pickerView: {
        backgroundColor: "#A7A5A5"
      }
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        backgroundColor: "#A7A5A5",
        "&:hover": {
          backgroundColor: "#6E5E68"
        }
      }
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#584B53"
      }
    },
    MuiRadio: {
      root: {
        color: grey[700]
      }
    },
    MuiSelect: {
      icon: {
        color: "#FCF9F8"
      }
    },
    MuiStepper: {
      root: {
        backgroundColor: "#FCF9F8",
        color: "#584B53"
      }
    },
    MuiSwitch: {
      track: {
        backgroundColor: grey[700]
      }
    },
    MuiTab: {
      textColorSecondary: {
        color: "#584B53"
      }
    },
    MuiTable: {
      root: {
        backgroundColor: grey[800],
        color: grey[300]
      }
    },
    MuiTableCell: {
      body: {
        backgroundColor: grey[800],
        borderColor: "#363636"
      },
      head: {
        backgroundColor: "#171415",
        color: "#FCF9F8",
        borderColor: "#171415"
      }
    },
    MuiTablePagination: {
      root: {
        borderColor: "#171415"
      }
    },
    MuiTableSortLabel: {
      root: {
        color: "#FCF9F8",
        "&:hover": {
          color: "#F36438"
        },
        "&:focus": {
          color: "#FCF9F8"
        },
        "&$active": {
          color: "#F36438"
        }
      },
      icon: {
        "&$iconDirectionAsc": {
          "& path": {
            color: "#F36438"
          }
        },
        "&$iconDirectionDesc": {
          "& path": {
            color: "#F36438"
          }
        }
      }
    },
    MuiToolbar: {
      root: {
        backgroundColor: "#171415",
        color: grey[700],
        borderColor: "#171415"
      }
    },
    MuiTypography: {
      caption: {
        color: grey[700]
      }
    }
  }
});

export default theme;
