import React from "react";
import { grey } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  buttonReset: {
    color: theme.palette.secondary.light
  },
  column: {
    flexBasis: "33.33%",
    padding: `0 ${theme.spacing(3)}px`
  },
  heading: {
    fontSize: theme.typography.pxToRem(15)
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2)
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline"
    }
  },
  root: {
    width: "100%"
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: grey[700]
  },
  subtitle: {
    marginBottom: theme.spacing(2),
    color: grey[700]
  },
  textField: {
    marginBottom: theme.spacing(1)
  }
}));

const initialState = {
  name: "",
  surname: "",
  phoneNumber: "",
  jobTitle: "",
  email: ""
};

export default function LeadEntryBar(props) {
  const classes = useStyles();
  const [state, setState] = React.useState(initialState);

  const handleFieldChange = (option, newValue) => {
    setState({
      ...state,
      [option]: newValue
    });
  };

  const handleReset = () => {
    setState(initialState);
  };

  const addContact = () => {
    handleReset();
    props.actions.createNewContact(
      state.name,
      state.surname,
      state.jobTitle,
      state.email,
      state.phoneNumber
    );
  };

  return (
    <div className={classes.root}>
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          <div className={classes.column}>
            <Typography className={classes.heading}>Contact Entry</Typography>
          </div>
          <div className={classes.column}>
            <Typography className={classes.secondaryHeading}>
              Add a new contact to this business
            </Typography>
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div className={classes.column}>
            <Typography
              className={classes.subtitle}
              variant="subtitle1"
              component="h3"
            >
              Basic Info
            </Typography>
            <TextField
              fullWidth
              type="name"
              id="name-input"
              label="Name"
              color="secondary"
              className={classes.textField}
              InputLabelProps={{
                shrink: true
              }}
              value={state.name}
              onChange={event => handleFieldChange("name", event.target.value)}
            />
            <TextField
              fullWidth
              type="surname"
              id="surname-input"
              label="Surname"
              color="secondary"
              className={classes.textField}
              InputLabelProps={{
                shrink: true
              }}
              value={state.surname}
              onChange={event =>
                handleFieldChange("surname", event.target.value)
              }
            />
            <TextField
              fullWidth
              type="jobTitle"
              id="job-title-input"
              label="Job Title"
              color="secondary"
              className={classes.textField}
              InputLabelProps={{
                shrink: true
              }}
              value={state.jobTitle}
              onChange={event =>
                handleFieldChange("jobTitle", event.target.value)
              }
            />
          </div>
          <div className={classes.column}>
            <Typography
              className={classes.subtitle}
              variant="subtitle1"
              component="h3"
            >
              Contact Details
            </Typography>
            <TextField
              required
              fullWidth
              type="email"
              id="email-input"
              label="Email Address"
              color="secondary"
              className={classes.textField}
              InputLabelProps={{
                shrink: true
              }}
              value={state.email}
              onChange={event => handleFieldChange("email", event.target.value)}
            />
            <TextField
              fullWidth
              type="tel"
              id="phone-number-input"
              label="Phone Number"
              color="secondary"
              className={classes.textField}
              InputLabelProps={{
                shrink: true
              }}
              value={state.phoneNumber}
              onChange={event =>
                handleFieldChange("phoneNumber", event.target.value)
              }
            />
          </div>
          <div className={classes.column}></div>
        </ExpansionPanelDetails>
        <Divider />
        <ExpansionPanelActions>
          <Button className={classes.buttonReset} onClick={() => handleReset()}>
            Reset
          </Button>
          <Button
            color="secondary"
            variant="contained"
            disabled={state.email === ""}
            onClick={() => addContact()}
          >
            Add
          </Button>
        </ExpansionPanelActions>
      </ExpansionPanel>
    </div>
  );
}
