import React from 'react';
import _ from 'lodash';
import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { Route, Switch, useLocation } from 'react-router-dom';
import BusinessInfo from '../BusinessInfo';
import Container from '@material-ui/core/Container';
import FiltersBar from './components/FiltersBar';
import LeadEntryBar from './components/LeadEntryBar';
import LeadsTable from './components/LeadsTable';
import moment from 'moment';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';

const sections = {
  NEW: 'New',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  ALL: 'All',
};

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(4),
  },
  headingSection: {
    color: grey[700],
  },
  headingTitle: {
    color: theme.palette.common.white,
    marginBottom: theme.spacing(2),
  },
  innerContent: {
    padding: theme.spacing(3),
  },
}));

const initialState = {
  activeSection: {
    name: 'New',
    value: 'NEW',
  },
  timeRange: 'ALL',
  fromDate: moment('20200101', 'YYYYMMDD').toDate(),
  toDate: moment().endOf('day').toDate(),
  createdBy: 'ALL',
  type: 'ALL',
  firstPaint: false,
};

export default function Leads(props) {
  const classes = useStyles();
  const [state, setState] = React.useState(initialState);
  let location = useLocation();

  React.useEffect(() => {
    if (
      !state.firstPaint &&
      props.userType !== 'LEADS_AGENT' &&
      props.userType !== 'SALES_AGENT' &&
      location.pathname === '/staff/leads'
    ) {
      props.actions.loadLeads(
        state.activeSection.value,
        state.createdBy,
        state.type,
        state.fromDate,
        state.toDate
      );
      setState({
        ...state,
        firstPaint: true,
      });
    }
    if (
      !state.firstPaint &&
      (props.userType === 'LEADS_AGENT' || props.userType === 'SALES_AGENT') &&
      location.pathname === '/staff/leads'
    ) {
      props.actions.loadLeads(
        state.activeSection.value,
        props.userId,
        state.type,
        state.fromDate,
        state.toDate
      );
      setState({
        ...state,
        firstPaint: true,
        createdBy: props.userId,
      });
    }
  }, [state, props, location]);

  const handleTabChange = (event, newActiveSection) => {
    if (newActiveSection !== 'NEW') {
      const timeRange = 'WEEK';
      const fromDate = moment().startOf('week').toDate();
      const toDate = moment().endOf('day').toDate();

      props.actions.loadLeads(
        newActiveSection,
        state.createdBy,
        state.type,
        fromDate,
        toDate
      );
      setState({
        ...state,
        activeSection: {
          name: sections[newActiveSection],
          value: newActiveSection,
        },
        fromDate,
        toDate,
        timeRange,
      });
    } else {
      const timeRange = 'ALL';
      const fromDate = moment('20000101', 'YYYYMMDD').toDate();
      const toDate = moment().endOf('day').toDate();

      props.actions.loadLeads(
        newActiveSection,
        state.createdBy,
        state.type,
        fromDate,
        toDate
      );
      setState({
        ...state,
        activeSection: {
          name: sections[newActiveSection],
          value: newActiveSection,
        },
        fromDate,
        toDate,
        timeRange,
      });
    }
  };

  const handleCreationDateChange = (newValue) => {
    let fromDate = state.fromDate;
    let toDate = state.toDate;

    switch (newValue) {
      case 'WEEK':
        fromDate = moment().startOf('week').toDate();
        toDate = moment().endOf('day').toDate();
        break;
      case 'MONTH':
        fromDate = moment().startOf('month').toDate();
        toDate = moment().endOf('day').toDate();
        break;
      case 'YEAR':
        fromDate = moment().startOf('year').toDate();
        toDate = moment().endOf('day').toDate();
        break;
      case 'ALL':
        fromDate = moment('20000101', 'YYYYMMDD').toDate();
        toDate = moment().endOf('day').toDate();
        break;
      default:
        fromDate = state.fromDate;
        toDate = state.toDate;
    }

    if (newValue !== 'CUSTOM') {
      props.actions.loadLeads(
        state.activeSection.value,
        state.createdBy,
        state.type,
        fromDate,
        toDate
      );
    }

    setState({
      ...state,
      fromDate,
      toDate,
      timeRange: newValue,
    });
  };

  const handleCreatedByChange = (newValue) => {
    props.actions.loadLeads(
      state.activeSection.value,
      newValue,
      state.type,
      state.fromDate,
      state.toDate
    );

    setState({
      ...state,
      createdBy: newValue,
    });
  };

  const handleTypeChange = (newValue) => {
    props.actions.loadLeads(
      state.activeSection.value,
      state.createdBy,
      newValue,
      state.fromDate,
      state.toDate
    );

    setState({
      ...state,
      type: newValue,
    });
  };

  const handleFromDateChange = (newDate) => {
    props.actions.loadLeads(
      state.activeSection.value,
      state.createdBy,
      state.type,
      newDate,
      state.toDate
    );

    setState({
      ...state,
      fromDate: moment(newDate).startOf('day').toDate(),
    });
  };

  const handleToDateChange = (newDate) => {
    if (moment(newDate).isBefore(state.fromDate)) {
      props.actions.loadLeads(
        state.activeSection.value,
        state.createdBy,
        state.type,
        moment(newDate).startOf('day').toDate(),
        moment(newDate).endOf('day').toDate()
      );

      setState({
        ...state,
        fromDate: moment(newDate).startOf('day').toDate(),
        toDate: moment(newDate).endOf('day').toDate(),
      });
    } else {
      props.actions.loadLeads(
        state.activeSection.value,
        state.createdBy,
        state.fromDate,
        state.type,
        moment(newDate).endOf('day').toDate()
      );

      setState({
        ...state,
        toDate: moment(newDate).endOf('day').toDate(),
      });
    }
  };

  return (
    <Switch>
      <Route exact path="/staff/leads">
        <div className={classes.header}>
          <Typography
            className={classes.headingSection}
            component="h1"
            variant="subtitle1"
          >
            {`${state.activeSection.name} Leads`}
          </Typography>
          <Typography
            className={classes.headingTitle}
            component="h2"
            variant="h3"
          >
            Leads Management
          </Typography>
        </div>
        <Tabs
          aria-label="leads tabs"
          textColor="secondary"
          value={state.activeSection.value}
          className={classes.tab}
          onChange={handleTabChange}
        >
          {_.toPairs(sections).map(([value, name]) => (
            <Tab
              key={value}
              label={name}
              value={value}
              className={classes.tab}
            />
          ))}
        </Tabs>
        <Container className={classes.innerContent}>
          {state.activeSection.value === 'NEW' && (
            <LeadEntryBar
              isLoading={props.isLoading}
              userId={props.userId}
              userName={props.userName}
              actions={{
                validateBusinessName: (name) =>
                  props.actions.validateBusinessName(name),
                validateBusinessWebsite: (websiteUrl) =>
                  props.actions.validateBusinessWebsite(websiteUrl),
                validateContactEmail: (email) =>
                  props.actions.validateContactEmail(email),
                validateContactPhoneNumber: (phoneNumber) =>
                  props.actions.validateContactPhoneNumber(phoneNumber),
                validateContactNameSurname: (text) =>
                  props.actions.validateContactNameSurname(text),
                createNewLead: (
                  businessInfo,
                  leadType,
                  creatorInfo,
                  primaryContactInfo
                ) =>
                  props.actions.createNewLead(
                    businessInfo,
                    leadType,
                    creatorInfo,
                    primaryContactInfo
                  ),
              }}
            />
          )}
          <FiltersBar
            users={props.users}
            userType={props.userType}
            createdBy={state.createdBy}
            timeRange={state.timeRange}
            fromDate={state.fromDate}
            toDate={state.toDate}
            type={state.type}
            actions={{
              handleCreationDateChange: (event) =>
                handleCreationDateChange(event.target.value),
              handleCreatedByChange: (event) =>
                handleCreatedByChange(event.target.value),
              handleTypeChange: (event) => handleTypeChange(event.target.value),
              handleFromDateChange: (fromDate) =>
                handleFromDateChange(fromDate),
              handleToDateChange: (toDate) => handleToDateChange(toDate),
            }}
          />
          <LeadsTable
            isLoading={props.isLoading}
            userType={props.userType}
            section={state.activeSection.value}
            data={props.leads}
            actions={{
              cleanBusinessRecord: (id) =>
                props.actions.cleanBusinessRecord(id),
              reviewLead: (id, feedback) =>
                props.actions.reviewLead(id, feedback),
            }}
          />
        </Container>
      </Route>
      <Route path="/staff/leads/:businessId">
        <BusinessInfo
          userType={props.userType}
          isLoading={props.isLoading}
          businessInfo={props.businessInfo}
          actions={{
            restoreContact: (id) => props.actions.restoreContact(id),
            deleteContact: (id) => props.actions.deleteContact(id),
            loadBusinessInfo: (id) => props.actions.loadBusinessInfo(id),
            loadBusinessContacts: (id) =>
              props.actions.loadBusinessContacts(id),
            validateBusinessName: (name) =>
              props.actions.validateBusinessName(name),
            validateBusinessWebsite: (websiteUrl) =>
              props.actions.validateBusinessWebsite(websiteUrl),
            editBusinessName: (id, businessName) =>
              props.actions.editBusinessName(id, businessName),
            editWebsiteUrl: (id, websiteUrl) =>
              props.actions.editWebsiteUrl(id, websiteUrl),
            editLeadType: (id, leadType) =>
              props.actions.editLeadType(id, leadType),
            makeContactPrimary: (id, otherContacts) =>
              props.actions.makeContactPrimary(id, otherContacts),
            createNewContact: (
              businessId,
              businessName,
              name,
              surname,
              jobTitle,
              email,
              phoneNumber
            ) =>
              props.actions.createNewContact(
                businessId,
                businessName,
                name,
                surname,
                jobTitle,
                email,
                phoneNumber
              ),
            editContactDetails: (id, newDetails) =>
              props.actions.editContactDetails(id, newDetails),
          }}
        />
      </Route>
    </Switch>
  );
}
