import React from "react";
import { grey } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(6),
    margin: `${theme.spacing(10)}px 0`
  },
  text: {
    color: grey[700]
  }
}));

export default function LeadsDashboard(props) {
  const classes = useStyles();

  return (
    <Container>
      <Grid container justify="center">
        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <Typography
              className={classes.text}
              variant="h4"
              component="h3"
              align="center"
            >
              Coming Soon
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
