import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  copyright: {
    color: theme.palette.secondary.light,
    padding: `${theme.spacing(2)}px 0`
  },
  rootWrapper: {
    backgroundColor: theme.palette.primary.main
  }
}));

export default function Footer(props) {
  const classes = useStyles();

  return (
    <div className={classes.rootWrapper}>
      <Container id="footer">
        <Typography
          variant="body1"
          component="p"
          align="center"
          className={classes.copyright}
        >
          2020 © EasyOnline (Pty) Ltd
        </Typography>
      </Container>
    </div>
  );
}
