import React from "react";
import { grey } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import firebase from "firebase/app";
import Grid from "@material-ui/core/Grid";
import MuiAlert from "@material-ui/lab/Alert";
import Paper from "@material-ui/core/Paper";
import Snackbar from "@material-ui/core/Snackbar";
import TextField from "@material-ui/core/TextField";
import "firebase/auth";

import logo from "./images/logo.png";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    color: grey[500],
  },
  container: {
    padding: `0 ${theme.spacing(2)}px`,
  },
  formWrapper: {
    padding: theme.spacing(4),
  },
  logo: {
    width: "100%",
    marginBottom: theme.spacing(3),
    cursor: "pointer",
    textAlign: "center",
  },
  rootWrapper: {
    padding: `${theme.spacing(20)}px 0`,
  },
  textField: {
    marginBottom: theme.spacing(4),
  },
}));

export default function ContactUs(props) {
  const classes = useStyles();
  const history = useHistory();

  const [state, setState] = React.useState({
    email: "",
    password: "",
    loading: false,
    isFailedAlertOpen: false,
  });

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setState({ ...state, isFailedAlertOpen: false });
  };

  const handleEmailChange = (event) => {
    setState({ ...state, email: event.target.value });
  };

  const handlePasswordChange = (event) => {
    setState({ ...state, password: event.target.value });
  };

  const handleFormSubmission = () => (event) => {
    event.preventDefault();

    setState({ ...state, loading: true });

    firebase
      .auth()
      .signInWithEmailAndPassword(state.email, state.password)
      .then(() => {
        setState({
          email: "",
          password: "",
          loading: false,
        });

        history.push("/staff");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;

        console.error(`${errorCode}: "${errorMessage}"`);

        setState({
          ...state,
          password: "",
          loading: false,
          isFailedAlertOpen: true,
        });
      });
  };

  return (
    <div className={classes.rootWrapper} id="contact-us">
      <Container>
        <Grid
          container
          spacing={4}
          alignItems="center"
          justify="center"
          direction="column"
          className={classes.container}
        >
          <Grid item xs={12} sm={9} md={6}>
            <Paper className={classes.formWrapper}>
              <div
                className={classes.logo}
                onClick={() => history.push("/")}
              >
                <img src={logo} alt="Logo" />
              </div>
              <form autoComplete="on">
                <TextField
                  required
                  fullWidth
                  type="email"
                  id="email-input"
                  label="Email Address"
                  color="secondary"
                  value={state.email}
                  onChange={handleEmailChange}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  required
                  fullWidth
                  type="password"
                  id="password-input"
                  label="Password"
                  color="secondary"
                  value={state.password}
                  onChange={handlePasswordChange}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <Button
                  fullWidth
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={state.loading}
                  onClick={handleFormSubmission()}
                >
                  {state.loading ? (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  ) : (
                    <span>Log in</span>
                  )}
                </Button>
              </form>
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <Snackbar
        open={state.isFailedAlertOpen}
        autoHideDuration={5000}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity="error">
          Login failed. Please try again.
        </Alert>
      </Snackbar>
    </div>
  );
}
