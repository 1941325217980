import React from "react";
import { grey } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import firebase from "firebase/app";
import Grid from "@material-ui/core/Grid";
import MuiAlert from "@material-ui/lab/Alert";
import Paper from "@material-ui/core/Paper";
import Snackbar from "@material-ui/core/Snackbar";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import "firebase/firestore";
import "firebase/analytics";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

// Initialize Cloud Firestore through Firebase
firebase.initializeApp({
  apiKey: "AIzaSyAFVIg-paYxeJSN2dZ67-2l1sUIHt7Tv2o",
  appId: "1:950981878546:web:1f96072a0a092ce60909cb",
  authDomain: "project-tagz.firebaseapp.com",
  projectId: "project-tagz",
  measurementId: "G-7QXX85TL0L"
});
firebase.analytics();

const db = firebase.firestore();

const useStyles = makeStyles(theme => ({
  buttonProgress: {
    color: grey[500]
  },
  container: {
    padding: `0 ${theme.spacing(2)}px`
  },
  formWrapper: {
    padding: theme.spacing(4)
  },
  introText: {
    color: theme.palette.primary.light
  },
  rootWrapper: {
    padding: `${theme.spacing(20)}px 0`
  },
  sectionHeading: {
    color: theme.palette.common.white
  },
  textField: {
    marginBottom: theme.spacing(4)
  }
}));

export default function ContactUs(props) {
  const classes = useStyles();

  const [state, setState] = React.useState({
    name: "",
    email: "",
    phoneNumber: "",
    comments: "",
    loading: false,
    isSuccessAlertOpen: false
  });

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setState({ ...state, isSuccessAlertOpen: false });
  };

  const handleNameChange = event => {
    setState({ ...state, name: event.target.value });
  };

  const handleEmailChange = event => {
    setState({ ...state, email: event.target.value });
  };

  const handlePhoneNumberChange = event => {
    setState({ ...state, phoneNumber: event.target.value });
  };

  const handleCommentsChange = event => {
    setState({ ...state, comments: event.target.value });
  };

  const handleFormSubmission = () => event => {
    event.preventDefault();

    setState({ ...state, loading: true });

    db.collection("contactFormSubmissions")
      .add({
        name: state.name,
        email: state.email,
        phoneNumber: state.phoneNumber,
        comments: state.comments
      })
      .then(docRef => {
        setState({
          name: "",
          email: "",
          phoneNumber: "",
          comments: "",
          loading: false,
          isSuccessAlertOpen: true
        });
      })
      .catch(error => {
        console.error("Error adding document: ", error);
      });
  };

  return (
    <div className={classes.rootWrapper} id="contact-us">
      <Container>
        <Grid
          container
          spacing={4}
          alignItems="center"
          justify="center"
          direction="column"
          className={classes.container}
        >
          <Grid item xs={12}>
            <Typography
              variant="h2"
              component="h2"
              align="center"
              className={classes.sectionHeading}
            >
              Contact Us
            </Typography>
          </Grid>
          <Grid item xs={12} sm={9} md={6}>
            <Typography
              variant="body1"
              component="p"
              align="left"
              className={classes.introText}
            >
              If you would like more info on how we can help you take your
              business to the next level, please enter your info below:
            </Typography>
          </Grid>
          <Grid item xs={12} sm={9} md={6}>
            <Paper className={classes.formWrapper}>
              <form autoComplete="on">
                <TextField
                  required
                  fullWidth
                  type="text"
                  id="name-input"
                  label="Name"
                  color="secondary"
                  value={state.name}
                  onChange={handleNameChange}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
                <TextField
                  required
                  fullWidth
                  type="email"
                  color="secondary"
                  id="email-input"
                  label="Email address"
                  value={state.email}
                  onChange={handleEmailChange}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
                <TextField
                  type="tel"
                  fullWidth
                  color="secondary"
                  id="phone-number-input"
                  label="Phone number"
                  value={state.phoneNumber}
                  onChange={handlePhoneNumberChange}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
                <TextField
                  multiline
                  fullWidth
                  rows="6"
                  type="text"
                  color="secondary"
                  id="comments-input"
                  label="Comments"
                  value={state.comments}
                  onChange={handleCommentsChange}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
                <Button
                  fullWidth
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={state.loading}
                  onClick={handleFormSubmission()}
                >
                  {state.loading ? (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  ) : (
                    <span>Send</span>
                  )}
                </Button>
              </form>
            </Paper>
          </Grid>
        </Grid>
        <Snackbar
          open={state.isSuccessAlertOpen}
          autoHideDuration={5000}
          onClose={handleAlertClose}
        >
          <Alert onClose={handleAlertClose} severity="success">
            We will be in touch soon!
          </Alert>
        </Snackbar>
      </Container>
    </div>
  );
}
