import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from "@material-ui/core/Grid";
import Switch from '@material-ui/core/Switch';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1)
  },
  root: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    marginBottom: theme.spacing(4)
  }
}));

export default function FiltersBar(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">Show Deleted Contacts</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={props.showDeleted}
                    onChange={props.actions.toggleShowDeleted}
                    name="showDeleted"
                  />
                }
                label={props.showDeleted ? "Show" : "Hide"}
              />
            </FormGroup>
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
}
