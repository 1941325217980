import React from "react";
import _ from "lodash";
import { grey, red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import firebase from "firebase/app";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Typography from "@material-ui/core/Typography";
import "firebase/firestore";

const useStyles = makeStyles(theme => ({
  buttonReset: {
    color: theme.palette.secondary.light
  },
  column: {
    flexBasis: "33.33%",
    padding: `0 ${theme.spacing(3)}px`
  },
  error: {
    color: red[500]
  },
  heading: {
    fontSize: theme.typography.pxToRem(15)
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2)
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline"
    }
  },
  root: {
    width: "100%"
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: grey[700]
  },
  subtitle: {
    marginBottom: theme.spacing(2),
    color: grey[700]
  },
  textField: {
    marginBottom: theme.spacing(1)
  }
}));

const initialState = {
  templateSelected: "COLD",
  agentSelected: null
};

const mailTemplateIds = {
  COLD: {
    withCellphone: "ee21823551fa41bd89d5e258a1f9491a",
    withoutCellphone: "c09cb5b214d14998b5f4e31b8c975c26"
  }
};

export default function CreateCampaignBar(props) {
  const classes = useStyles();
  const [state, setState] = React.useState(initialState);

  const handleTemplateChange = event => {
    setState({
      ...state,
      templateSelected: event.target.value
    });
  };

  const handleReset = () => {
    props.actions.resetRowsSelected();
    setState(initialState);
  };

  const handleAgentChange = event => {
    setState({
      ...state,
      agentSelected: event.target.value
    });
  };

  const getAgentOptions = users => {
    return _.toPairs(users)
      .filter(
        ([id, info]) =>
          info.type === "SALES_AGENT" || info.type === "SALES_MANAGER"
      )
      .map(([id, info]) => (
        <FormControlLabel
          key={id}
          value={id}
          control={<Radio />}
          label={`${info.name} ${info.surname}`}
        />
      ));
  };

  const handleAddCampaign = (recordsSelected, agentInfo) => {
    const senderInfo = {
      id: agentInfo.id,
      ref: firebase
        .firestore()
        .collection("users")
        .doc(agentInfo.id),
      name: agentInfo.name,
      surname: agentInfo.surname,
      email: agentInfo.email,
      phoneNumber: agentInfo.phoneNumber,
      title: agentInfo.title
    };
    const templateId = agentInfo.signature.showPersonalNumber
      ? mailTemplateIds[state.templateSelected].withCellphone
      : mailTemplateIds[state.templateSelected].withoutCellphone;
    const businessIds = recordsSelected.map(info => info.id);

    handleReset();
    props.actions.createNewCampaign(businessIds, senderInfo, templateId);
  };

  return (
    <div className={classes.root}>
      <ExpansionPanel defaultExpanded>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          <div className={classes.column}>
            <Typography className={classes.heading}>Create Campaign</Typography>
          </div>
          <div className={classes.column}>
            <Typography className={classes.secondaryHeading}>
              Send out a new sales email campaign
            </Typography>
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div className={classes.column}>
            <Typography
              className={
                props.recordsSelected.length > 100
                  ? classes.error
                  : classes.subtitle
              }
              variant="subtitle1"
              component="h3"
            >
              Records Selected
            </Typography>
            <Typography variant="h2" component="p">
              {props.recordsSelected.length}
            </Typography>
            {props.recordsSelected.length > 100 && (
              <Typography
                className={classes.error}
                variant="subtitle1"
                component="h3"
              >
                Too many records selected (max. 100)
              </Typography>
            )}
          </div>
          <div className={classes.column}>
            <Typography
              className={classes.subtitle}
              variant="subtitle1"
              component="h3"
            >
              Sales Agent
            </Typography>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="lead type"
                name="lead-type"
                value={state.agentSelected}
                onChange={handleAgentChange}
              >
                {getAgentOptions(props.users)}
              </RadioGroup>
            </FormControl>
          </div>
          <div className={classes.column}>
            <Typography
              className={classes.subtitle}
              variant="subtitle1"
              component="h3"
            >
              Email Template
            </Typography>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="email template"
                name="lead-type"
                value={state.templateSelected}
                onChange={handleTemplateChange}
              >
                <FormControlLabel
                  value="COLD"
                  control={<Radio />}
                  label="Cold Mail"
                />
              </RadioGroup>
            </FormControl>
          </div>
        </ExpansionPanelDetails>
        <Divider />
        <ExpansionPanelActions>
          <Button className={classes.buttonReset} onClick={() => handleReset()}>
            Reset
          </Button>
          <Button
            color="secondary"
            variant="contained"
            disabled={
              state.agentSelected === null ||
              props.recordsSelected.length === 0 ||
              props.recordsSelected.length > 100
            }
            onClick={() =>
              handleAddCampaign(
                props.recordsSelected,
                props.users[state.agentSelected]
              )
            }
          >
            Send Emails
          </Button>
        </ExpansionPanelActions>
      </ExpansionPanel>
    </div>
  );
}
