import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import ViableProductsCard from "./components/ViableProductsCard";

export default function ComingSoon(props) {
  return (
    <Container>
      <Grid container>
        <Grid item xs={4}>
          <ViableProductsCard />
        </Grid>
      </Grid>
    </Container>
  );
}
