import React from "react";
import _ from "lodash";
import { grey } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Collapse from "@material-ui/core/Collapse";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

const useStyles = makeStyles(theme => ({
  dialogContent: {
    width: 400,
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main
  },
  nested: {
    color: grey[800],
    paddingLeft: theme.spacing(2)
  },
  subheader: {
    color: theme.palette.common.black,
    backgroundColor: theme.palette.primary.light
  }
}));

const initialState = {
  viableProducts: {
    "Skin Care (Face)": {
      isOpen: false,
      items: [
        "Cleansers",
        "Creams & Moisturizers",
        "Exfoliators",
        "Face Treatments",
        "Toners & Astringents"
      ]
    },
    "Skin Care (Eyes)": {
      isOpen: false,
      items: ["Creams", "Masks"]
    },
    "Skin Care (Body)": {
      isOpen: false,
      items: ["Foot Care", "Moisturizers"]
    },
    Minerals: {
      isOpen: false,
      items: [
        "Calcium",
        "Chromium",
        "Colloidal Minerals",
        "Copper",
        "Iodine",
        "Iron",
        "Magnesium",
        "Manganese",
        "Multiminerals",
        "Potassium",
        "Selenium",
        "Silicon",
        "Sodium",
        "Trace Minerals",
        "Zinc"
      ]
    },
    "Herbal Supplements": {
      isOpen: false,
      items: [
        "Alfalfa",
        "Aloe Vera",
        "Artichoke",
        "Astragalus",
        "Bilberry",
        "Black Cohosh",
        "Cayenne",
        "Chamomile",
        "Chlorella",
        "Chlorophyll",
        "Cinnamon",
        "Cranberry",
        "Curcumin",
        "Dong Quai",
        "Echinacea",
        "Elderberry",
        "Evening Primrose",
        "Flaxseed",
        "Garlic",
        "Ginger",
        "Ginkgo Biloba",
        "Ginseng",
        "Goldenseal",
        "Grape Seed Extract",
        "Green Tea",
        "Hawthorn",
        "Horny Goat Weed",
        "Kava Kava",
        "Licorice Root",
        "Marshmallow Root",
        "Milk Thistle",
        "Muira Puama",
        "Mushrooms",
        "Neem",
        "Nettle",
        "Noni",
        "Olive Leaf",
        "Oregano",
        "Red Yeast Rice",
        "Saw Palmetto",
        "Spirulina",
        "St. John's Wort",
        "Stevia",
        "Tribulus",
        "Triphala",
        "Turmeric",
        "Valerian",
        "Yohimbe"
      ]
    },
    Vitamins: {
      isOpen: false,
      items: [
        "Blended Vitamin & Mineral Supplements",
        "Choline",
        "Flavonoids",
        "Multivitamins",
        "Prenatal Vitamins",
        "Vitamin A",
        "Vitamin B",
        "Vitamin C",
        "Vitamin D",
        "Vitamin E"
      ]
    }
  }
};

export default function ViableProductsDialog(props) {
  const classes = useStyles();
  const [state, setState] = React.useState(initialState);

  const handleOpenToggle = category => {
    setState({
      ...state,
      viableProducts: {
        ...state.viableProducts,
        [category]: {
          ...state.viableProducts[category],
          isOpen: !state.viableProducts[category].isOpen
        }
      }
    });
  };

  return (
    <Dialog
      open={props.isOpen}
      onClose={props.actions.handleDialogClose}
      scroll="paper"
    >
      <DialogTitle id="scroll-dialog-title" className={classes.dialogHeader}>
        Viable Products List
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <List>
          {_.toPairs(state.viableProducts).map(([categoryName, info]) => (
            <React.Fragment key={categoryName}>
              <ListItem
                button
                onClick={event => handleOpenToggle(categoryName)}
              >
                <ListItemText primary={categoryName} />
                {info.isOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={info.isOpen} timeout="auto" unmountOnExit>
                {info.items.map(item => (
                  <List component="div" disablePadding key={item}>
                    <ListItem>
                      <ListItemText primary={item} className={classes.nested} />
                    </ListItem>
                  </List>
                ))}
              </Collapse>
            </React.Fragment>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.actions.handleDialogClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
