import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import MobileStepper from "@material-ui/core/MobileStepper";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";

import amazonLogoImg from "./images/amazon-logo.jpg";
import analysisImg from "./images/analysis.jpg";
import courierImg from "./images/courier.jpg";
import marketingImg from "./images/marketing.jpg";
import onlineShopperImg from "./images/online-shopper.jpg";
import paymentImg from "./images/payment.jpg";
import photographyStudioImg from "./images/photography-studio.jpg";
import reportImg from "./images/report.jpg";
import shippingImg from "./images/shipping.jpg";
import takealotLogoImg from "./images/takealot-logo.jpg";

const amazonUSSteps = [
  {
    label: "Access the US Marketplace",
    imgPath: amazonLogoImg,
    copy:
      "Over 200 million shoppers access Amazon's US marketplace on a regular basis. Here we explain how we can help you gain access to this massive market."
  },
  {
    label: "1. Do Research & Create A Proposal",
    imgPath: analysisImg,
    copy:
      "While ecommerce is an exciting investment, it's important to first understand how to price your product profitably and whether that price is competitive on Amazon. We call this a viability study, and we do this for free. If your product is viable, we send you a proposal with the details within a week."
  },
  {
    label: "2. Prepare Your Listing",
    imgPath: photographyStudioImg,
    copy:
      "Your listing is your product's online persona, and so it's important that it sends the right message. Once you've accepted the proposal, we begin work on drafting emotive, keyword-rich copy and shooting beauitful studio-quality product images. This is all done while your units are being shipped."
  },
  {
    label: "3. Manage Inventory Shipping & Storage",
    imgPath: shippingImg,
    copy:
      "We will let you know how many units we need. Once you're ready, we will have the units picked up and brought to our warehouse to prepare according to Amazon requirements. We then send your first consignment via air to the USA. This entire process takes 2 - 3 weeks."
  },
  {
    label: "4. Market Your Product",
    imgPath: marketingImg,
    copy:
      "Once your listing is live, the real work begins. It's easy for a new product to be lost in a sea of competition. We will make sure that doesn't happen to your product. Our partnered marketing agency immediately gets to work spreading the word about your product."
  },
  {
    label: "5. Handle Customer Service",
    imgPath: onlineShopperImg,
    copy:
      "As sales start coming, so too will customer queries. We make sure the customer experience is smooth through timely, helpful and curtious responses."
  },
  {
    label: "6. Provide You With Regular Reporting",
    imgPath: reportImg,
    copy:
      "At the end of the day, we know that this is your brand and your product, and so we empower you with the information you need to make decisions going forward. Our reports provide data pertaining to sales and marketing activity. We provide our insights, but leave the final decision up to you."
  },
  {
    label: "7. Pay You for Monthly Unit Sales",
    imgPath: paymentImg,
    copy:
      "We will send you a monthly statement of sales containing the number of units sold and how much is due to you for said sales. You just need to send us an invoice in response and we will pay you soon after."
  }
];

const takealotSteps = [
  {
    label: "Local Online Retail Made Easy",
    imgPath: takealotLogoImg,
    copy:
      "Recent events have led to South Africans joining the global ecommerce revolution and Takealot is at the forefront of this. We can make trading your products on Takealot a lot less of a headache."
  },
  {
    label: "1. Do Research & Create A Proposal",
    imgPath: analysisImg,
    copy:
      "We use our bespoke costing tool to prepare profitable price points for your product, factoring in Takealot's fees, transportation costs, and most importantly your margin. We provide you with a proposal containing this costing within a week."
  },
  {
    label: "2. Prepare Your Listing",
    imgPath: photographyStudioImg,
    copy:
      "Your listing is your product's online persona, and so it's important that it sends the right message. Once you've accepted the proposal, we begin work on drafting emotive, keyword-rich copy and shooting beauitful studio-quality product images."
  },
  {
    label: "3. Manage Inventory Transport & Storage",
    imgPath: courierImg,
    copy:
      "We will let you know how many units we need. Once you're ready, we will have the units picked up and brought to our warehouse to prepare according to Takealot requirements. We then send your stock to Takealot's Johannesburg and Cape Town distribution centres. This entire process takes less than a week."
  },
  {
    label: "4. Handle Customer Service",
    imgPath: onlineShopperImg,
    copy:
      "As sales start coming, so too will customer queries. We make sure the customer experience is smooth through timely, helpful and curtious responses."
  },
  {
    label: "5. Provide You With Regular Reporting",
    imgPath: reportImg,
    copy:
      "At the end of the day, we know that this is your brand and your product, and so we empower you with the information you need to make decisions going forward. Our reports provide data pertaining to sales activity. We provide our insights, but leave the final decision up to you."
  },
  {
    label: "6. Pay You for Monthly Unit Sales",
    imgPath: paymentImg,
    copy:
      "We will send you a monthly statement of sales containing the number of units sold and how much is due to you for said sales. You just need to send us an invoice in response and we will pay you soon after."
  }
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={0}>{children}</Box>}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  container: {
    padding: `0 ${theme.spacing(2)}px`
  },
  explanationText: {
    margin: `${theme.spacing(3)}px 0`,
    color: theme.palette.primary.light
  },
  img: {
    overflow: "hidden",
    display: "block",
    width: "100%",
    marginBottom: theme.spacing(3)
  },
  introText: {
    color: theme.palette.primary.light
  },
  rootWrapper: {
    padding: `${theme.spacing(20)}px 0`
  },
  sectionHeading: {
    color: theme.palette.common.white
  },
  stepper: {
    flexGrow: 1,
    color: theme.palette.primary.light,
    backgroundColor: theme.palette.primary.main
  },
  stepperContent: {
    padding: theme.spacing(3)
  },
  tab: {
    color: theme.palette.secondary.light
  }
}));

export default function OurProcess(props) {
  const classes = useStyles();
  const theme = useTheme();

  const [tabValue, setTabValue] = React.useState("amazon-us");
  const [activeAmazonUSStep, setActiveAmazonUSStep] = React.useState(0);
  const [activeTakealotStep, setActiveTakealotStep] = React.useState(0);
  const maxAmazonUSSteps = amazonUSSteps.length;
  const maxTakealotSteps = takealotSteps.length;

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setActiveAmazonUSStep(0);
    setActiveTakealotStep(0);
  };

  const handleAmazonUSNext = () => {
    setActiveAmazonUSStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleAmazonUSBack = () => {
    setActiveAmazonUSStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleTakealotNext = () => {
    setActiveTakealotStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleTakealotBack = () => {
    setActiveTakealotStep(prevActiveStep => prevActiveStep - 1);
  };

  return (
    <div className={classes.rootWrapper} id="our-process">
      <Container>
        <Grid
          container
          spacing={4}
          alignItems="center"
          justify="center"
          direction="column"
          className={classes.container}
        >
          <Grid item xs={12}>
            <Typography
              variant="h2"
              component="h2"
              align="center"
              className={classes.sectionHeading}
            >
              Our Process
            </Typography>
          </Grid>
          <Grid item xs={12} sm={9} md={6}>
            <Typography
              variant="body1"
              component="p"
              align="left"
              className={classes.introText}
            >
              You're probably wondering what exactly we can do for your
              business. Let's start by explaining our process for Amazon US and
              Takealot:
            </Typography>
          </Grid>
          <Grid item xs={12} sm={9} md={6}>
            <Paper>
              <Tabs
                indicatorColor="secondary"
                textColor="secondary"
                onChange={handleTabChange}
                value={tabValue}
                centered
              >
                <Tab
                  label="Amazon US"
                  value="amazon-us"
                  className={classes.tab}
                />
                <Tab
                  label="Takealot"
                  value="takealot"
                  className={classes.tab}
                />
              </Tabs>
              <TabPanel value={tabValue} index="amazon-us">
                <div className={classes.stepperContent}>
                  <img
                    className={classes.img}
                    src={amazonUSSteps[activeAmazonUSStep].imgPath}
                    alt={amazonUSSteps[activeAmazonUSStep].label}
                  />
                  <Typography variant="h6" component="h4" align="left">
                    {amazonUSSteps[activeAmazonUSStep].label}
                  </Typography>
                  <Typography
                    variant="body1"
                    component="p"
                    align="left"
                    className={classes.explanationText}
                  >
                    {amazonUSSteps[activeAmazonUSStep].copy}
                  </Typography>
                </div>
                <MobileStepper
                  steps={maxAmazonUSSteps}
                  variant="text"
                  position="static"
                  activeStep={activeAmazonUSStep}
                  className={classes.stepper}
                  nextButton={
                    <Button
                      size="small"
                      color="secondary"
                      onClick={handleAmazonUSNext}
                      disabled={activeAmazonUSStep === maxAmazonUSSteps - 1}
                    >
                      Next
                      {theme.direction === "rtl" ? (
                        <KeyboardArrowLeft />
                      ) : (
                        <KeyboardArrowRight />
                      )}
                    </Button>
                  }
                  backButton={
                    <Button
                      size="small"
                      color="secondary"
                      onClick={handleAmazonUSBack}
                      disabled={activeAmazonUSStep === 0}
                    >
                      {theme.direction === "rtl" ? (
                        <KeyboardArrowRight />
                      ) : (
                        <KeyboardArrowLeft />
                      )}
                      Back
                    </Button>
                  }
                />
              </TabPanel>
              <TabPanel value={tabValue} index="takealot">
                <div className={classes.stepperContent}>
                  <img
                    className={classes.img}
                    src={takealotSteps[activeTakealotStep].imgPath}
                    alt={takealotSteps[activeTakealotStep].label}
                  />
                  <Typography variant="h6" component="h4" align="left">
                    {takealotSteps[activeTakealotStep].label}
                  </Typography>
                  <Typography
                    variant="body1"
                    component="p"
                    align="left"
                    className={classes.explanationText}
                  >
                    {takealotSteps[activeTakealotStep].copy}
                  </Typography>
                </div>
                <MobileStepper
                  steps={maxTakealotSteps}
                  variant="text"
                  position="static"
                  activeStep={activeTakealotStep}
                  className={classes.stepper}
                  nextButton={
                    <Button
                      size="small"
                      onClick={handleTakealotNext}
                      disabled={activeTakealotStep === maxTakealotSteps - 1}
                      color="secondary"
                    >
                      Next
                      {theme.direction === "rtl" ? (
                        <KeyboardArrowLeft />
                      ) : (
                        <KeyboardArrowRight />
                      )}
                    </Button>
                  }
                  backButton={
                    <Button
                      size="small"
                      onClick={handleTakealotBack}
                      disabled={activeTakealotStep === 0}
                      color="secondary"
                    >
                      {theme.direction === "rtl" ? (
                        <KeyboardArrowRight />
                      ) : (
                        <KeyboardArrowLeft />
                      )}
                      Back
                    </Button>
                  }
                />
              </TabPanel>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
