import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Step from "@material-ui/core/Step";
import StepContent from "@material-ui/core/StepContent";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  actionsContainer: {
    marginBottom: theme.spacing(2)
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  container: {
    padding: `0 ${theme.spacing(2)}px`
  },
  introText: {
    color: theme.palette.secondary.light
  },
  resetContainer: {
    padding: theme.spacing(3)
  },
  rootWrapper: {
    backgroundColor: theme.palette.common.white,
    padding: `${theme.spacing(20)}px 0`
  },
  stepperWrapper: {
    width: "100%"
  }
}));

function getSteps() {
  return [
    "We tried to sell products online.",
    "We realised how difficult it is...",
    "We figured it out!",
    "We started helping businesses get into e-commerce."
  ];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return "Excited to get started, we flew all the way to the US just to set up a US business for our Amazon account.";
    case 1:
      return "Once we started trading, we realised we were in over our head. Getting over the compliance hurdles was hard enough, but then we had to manage photoshoots, copywriting, inventory, marketing and much more.";
    case 2:
      return "While there were many growing pains, we eventually came out the other side with a firm understanding of what's required to make ecommerce a lucrative investment.";
    case 3:
      return "We realised how much time and money we would have saved had we known then what we know now. This realization led to us developing services to help businesses like yours thrive online!";
    default:
      return "Unknown step";
  }
}

export default function OurStory(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  return (
    <div className={classes.rootWrapper} id="our-story">
      <Container>
        <Grid
          container
          spacing={6}
          alignItems="center"
          justify="center"
          direction="column"
          className={classes.container}
        >
          <Grid item xs={12}>
            <Typography
              variant="h2"
              component="h2"
              align="center"
              className={classes.sectionHeading}
            >
              Our Story
            </Typography>
          </Grid>
          <Grid item xs={12} sm={9} md={6}>
            <Typography
              variant="body1"
              component="p"
              align="left"
              className={classes.introText}
            >
              More than 5 years ago, we realised the power of ecommerce and
              decided to dive in head first.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={9} md={6}>
            <div className={classes.stepperWrapper}>
              <Stepper
                activeStep={activeStep}
                orientation="vertical"
              >
                {steps.map((label, index) => (
                  <Step className={classes.step} key={label}>
                    <StepLabel>{label}</StepLabel>
                    <StepContent>
                      <Typography>{getStepContent(index)}</Typography>
                      <div className={classes.actionsContainer}>
                        <div>
                          <Button
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            className={classes.button}
                          >
                            Back
                          </Button>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleNext}
                            disabled={activeStep === steps.length - 1}
                            className={classes.button}
                          >
                            Next
                          </Button>
                        </div>
                      </div>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
