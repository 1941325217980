import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import DrawerMenu from "./components/DrawerMenu";
import Toolbar from "@material-ui/core/Toolbar";
import UserInfo from "./components/UserInfo";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  divider: {
    backgroundColor: theme.palette.primary.dark
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerContainer: {
    overflow: "auto"
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.primary.main
  },
  loadingWrapper: {
    flexGrow: 1,
    padding: theme.spacing(6),
    display: "flex",
    justifyContent: "center"
  }
}));

export default function PortalDrawer(props) {
  const classes = useStyles();

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper
      }}
    >
      <Toolbar />
      {props.isLoading ? (
        <div className={classes.loadingWrapper}>
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <div className={classes.drawerContainer}>
          <UserInfo
            isLoading={props.isLoading}
            name={props.name}
            title={props.title}
          />
          <Divider className={classes.divider} />
          <DrawerMenu
            userType={props.userType}
            activeSection={props.activeSection}
            actions={{
              handleSectionChange: newSection =>
                props.actions.handleSectionChange(newSection)
            }}
          />
        </div>
      )}
    </Drawer>
  );
}
