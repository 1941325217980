import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AssignmentIcon from "@material-ui/icons/Assignment";
import Drawer from "@material-ui/core/Drawer";
import HomeIcon from "@material-ui/icons/Home";
import InfoIcon from "@material-ui/icons/Info";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MailIcon from "@material-ui/icons/Mail";
import RoomServiceIcon from "@material-ui/icons/RoomService";

const useStyles = makeStyles(theme => ({
  drawerIcon: {
    color: theme.palette.secondary.main
  }
}));

export default function MobileMenu(props) {
  const classes = useStyles();
  const { isDrawerOpen, toggleDrawer, changeActiveLink } = props;

  return (
    <Drawer
      anchor="right"
      open={isDrawerOpen}
      onClose={() => toggleDrawer(false)}
    >
      <List>
        <ListItem button onClick={changeActiveLink("home", true)}>
          <ListItemIcon>
            <HomeIcon className={classes.drawerIcon} />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem button onClick={changeActiveLink("our-story", true)}>
          <ListItemIcon>
            <InfoIcon className={classes.drawerIcon} />
          </ListItemIcon>
          <ListItemText primary="Our Story" />
        </ListItem>
        <ListItem button onClick={changeActiveLink("our-process", true)}>
          <ListItemIcon>
            <AssignmentIcon className={classes.drawerIcon} />
          </ListItemIcon>
          <ListItemText primary="Our Process" />
        </ListItem>
        <ListItem button onClick={changeActiveLink("our-services", true)}>
          <ListItemIcon>
            <RoomServiceIcon className={classes.drawerIcon} />
          </ListItemIcon>
          <ListItemText primary="Our Services" />
        </ListItem>
        <ListItem button onClick={changeActiveLink("contact-us", true)}>
          <ListItemIcon>
            <MailIcon className={classes.drawerIcon} />
          </ListItemIcon>
          <ListItemText primary="Contact Us" />
        </ListItem>
      </List>
    </Drawer>
  );
}
