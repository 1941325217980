import React from "react";
import { grey } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ViableProductsDialog from "./components/ViableProductsDialog";

const useStyles = makeStyles(theme => ({
  buttonReset: {
    color: theme.palette.secondary.light
  },
  column: {
    flexBasis: "33.33%",
    padding: `0 ${theme.spacing(3)}px`
  },
  formControlLabel: {
    color: grey[700]
  },
  heading: {
    fontSize: theme.typography.pxToRem(15)
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2)
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline"
    }
  },
  root: {
    width: "100%"
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: grey[700]
  },
  subtitle: {
    marginBottom: theme.spacing(2),
    color: theme.palette.common.white
  },
  textField: {
    marginBottom: theme.spacing(1)
  }
}));

const initialState = {
  leadType: "COLD",
  businessName: {
    error: false,
    value: "",
    message: ""
  },
  businessWebsite: {
    error: false,
    value: "",
    message: ""
  },
  contactEmail: {
    error: false,
    value: "",
    message: ""
  },
  contactPhoneNumber: {
    error: false,
    value: "",
    message: ""
  },
  contactName: {
    error: false,
    value: "",
    message: ""
  },
  contactSurname: {
    error: false,
    value: "",
    message: ""
  },
  qa: {
    websiteActive: false,
    viableProducts: false,
    southAfricanBrand: false
  },
  isViableProductCategoriesOpen: false
};

export default function LeadEntryBar(props) {
  const classes = useStyles();
  const [state, setState] = React.useState(initialState);

  const handleLeadTypeChange = event => {
    setState({
      ...state,
      leadType: event.target.value
    });
  };

  const handleBusinessNameChange = event => {
    setState({
      ...state,
      businessName: {
        error: false,
        value: event.target.value,
        message: ""
      }
    });
  };

  const validateBusinessName = async name => {
    const validation = await props.actions.validateBusinessName(name);
    setState({
      ...state,
      businessName: {
        error: validation.error,
        value: name,
        message: validation.message
      }
    });
  };

  const handleBusinessWebsiteChange = event => {
    setState({
      ...state,
      businessWebsite: {
        error: false,
        value: event.target.value,
        message: ""
      }
    });
  };

  const validateBusinessWebsite = async websiteUrl => {
    const validation = await props.actions.validateBusinessWebsite(websiteUrl);
    setState({
      ...state,
      businessWebsite: {
        error: validation.error,
        value: websiteUrl,
        message: validation.message
      }
    });
  };

  const handleContactEmailChange = event => {
    setState({
      ...state,
      contactEmail: {
        error: false,
        value: event.target.value,
        message: ""
      }
    });
  };

  const validateContactEmail = async email => {
    const validation = await props.actions.validateContactEmail(email);
    setState({
      ...state,
      contactEmail: {
        error: validation.error,
        value: email,
        message: validation.message
      }
    });
  };

  const handleContactPhoneNumberChange = event => {
    setState({
      ...state,
      contactPhoneNumber: {
        error: false,
        value: event.target.value,
        message: ""
      }
    });
  };

  const validateContactPhoneNumber = async phoneNumber => {
    const validation = await props.actions.validateContactPhoneNumber(
      phoneNumber
    );
    setState({
      ...state,
      contactPhoneNumber: {
        error: validation.error,
        value: phoneNumber,
        message: validation.message
      }
    });
  };

  const handleContactNameChange = event => {
    setState({
      ...state,
      contactName: {
        error: false,
        value: event.target.value,
        message: ""
      }
    });
  };

  const validateContactName = async name => {
    const validation = await props.actions.validateContactNameSurname(name);
    setState({
      ...state,
      contactName: {
        error: validation.error,
        value: name,
        message: validation.message
      }
    });
  };

  const handleContactSurnameChange = event => {
    setState({
      ...state,
      contactSurname: {
        error: false,
        value: event.target.value,
        message: ""
      }
    });
  };

  const validateContactSurname = async name => {
    const validation = await props.actions.validateContactNameSurname(name);
    setState({
      ...state,
      contactSurname: {
        error: validation.error,
        value: name,
        message: validation.message
      }
    });
  };

  const validateRequiredFields = () => {
    return (
      state.businessName.value !== "" &&
      state.businessWebsite.value !== "" &&
      state.contactEmail.value !== ""
    );
  };

  const isAllInfoValid = () => {
    return (
      state.businessName.error ||
      state.businessWebsite.error ||
      state.contactEmail.error ||
      state.contactPhoneNumber.error ||
      state.contactName.error ||
      state.contactSurname.error ||
      !state.qa.viableProducts ||
      !state.qa.southAfricanBrand ||
      !state.qa.websiteActive
    );
  };

  const handleAddLead = () => {
    if (!validateRequiredFields()) {
      setState({
        ...state,
        businessName: {
          error: state.businessName.value.length === 0,
          value: state.businessName.value,
          message: state.businessName.value.length === 0 ? "Required" : ""
        },
        businessWebsite: {
          error: state.businessWebsite.value.length === 0,
          value: state.businessWebsite.value,
          message: state.businessWebsite.value.length === 0 ? "Required" : ""
        },
        contactEmail: {
          error: state.contactEmail.value.length === 0,
          value: state.contactEmail.value,
          message: state.contactEmail.value.length === 0 ? "Required" : ""
        }
      });
    } else {
      const businessInfo = {
        name: state.businessName.value,
        websiteUrl: state.businessWebsite.value
      };
      const leadType = state.leadType;
      const creatorInfo = {
        id: props.userId,
        name: props.userName
      };
      const primaryContactInfo = {
        email: state.contactEmail.value,
        phoneNumber: state.contactPhoneNumber.value,
        name: state.contactName.value,
        surname: state.contactSurname.value
      };

      props.actions.createNewLead(
        businessInfo,
        leadType,
        creatorInfo,
        primaryContactInfo
      );
      handleFormReset();
    }
  };

  const handleFormReset = () => {
    setState(initialState);
  };

  const handleQaCheck = check => event => {
    setState({
      ...state,
      qa: {
        ...state.qa,
        [check]: !state.qa[check]
      }
    });
  };

  const handleViableProductsListToggle = isOpen => {
    setState({
      ...state,
      isViableProductCategoriesOpen: isOpen
    });
  };

  const isAddButtonDisabled = isAllInfoValid() || props.isLoading;

  return (
    <div className={classes.root}>
      <ExpansionPanel defaultExpanded>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          <div className={classes.column}>
            <Typography className={classes.heading}>Lead Entry</Typography>
          </div>
          <div className={classes.column}>
            <Typography className={classes.secondaryHeading}>
              Add a new lead to the database
            </Typography>
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div className={classes.column}>
            <Typography
              className={classes.subtitle}
              variant="subtitle1"
              component="h3"
            >
              Business Info
            </Typography>
            <TextField
              required
              fullWidth
              type="text"
              id="business-name-input"
              label="Business Name"
              color="secondary"
              className={classes.textField}
              error={state.businessName.error}
              helperText={state.businessName.message}
              value={state.businessName.value}
              onChange={handleBusinessNameChange}
              inputProps={{
                onBlur: () => validateBusinessName(state.businessName.value)
              }}
              InputLabelProps={{
                shrink: true
              }}
            />
            <TextField
              required
              fullWidth
              type="text"
              id="website-input"
              label="Website"
              color="secondary"
              className={classes.textField}
              error={state.businessWebsite.error}
              helperText={state.businessWebsite.message}
              value={state.businessWebsite.value}
              onChange={handleBusinessWebsiteChange}
              inputProps={{
                onBlur: () =>
                  validateBusinessWebsite(state.businessWebsite.value)
              }}
              InputLabelProps={{
                shrink: true
              }}
            />
            <Typography
              className={classes.subtitle}
              variant="subtitle1"
              component="h3"
            >
              Quality Checks
            </Typography>
            <FormGroup>
              <FormControlLabel
                className={classes.formControlLabel}
                control={
                  <Checkbox
                    checked={state.qa.websiteActive}
                    onChange={handleQaCheck("websiteActive")}
                    name="websiteActive"
                  />
                }
                label="Website is active"
              />
              <FormControlLabel
                className={classes.formControlLabel}
                control={
                  <Checkbox
                    checked={state.qa.viableProducts}
                    onChange={handleQaCheck("viableProducts")}
                    name="viableProducts"
                  />
                }
                label="Business sells viable products"
              />
              <FormControlLabel
                className={classes.formControlLabel}
                control={
                  <Checkbox
                    checked={state.qa.southAfricanBrand}
                    onChange={handleQaCheck("southAfricanBrand")}
                    name="southAfricanBrand"
                  />
                }
                label="Business trades South African brands"
              />
            </FormGroup>
          </div>
          <div className={classes.column}>
            <Typography
              className={classes.subtitle}
              variant="subtitle1"
              component="h3"
            >
              Contact Info
            </Typography>
            <TextField
              required
              fullWidth
              type="email"
              id="email-input"
              label="Email Address"
              color="secondary"
              className={classes.textField}
              error={state.contactEmail.error}
              helperText={state.contactEmail.message}
              value={state.contactEmail.value}
              onChange={handleContactEmailChange}
              inputProps={{
                onBlur: () => validateContactEmail(state.contactEmail.value)
              }}
              InputLabelProps={{
                shrink: true
              }}
            />
            <TextField
              fullWidth
              type="tel"
              id="phone-number-input"
              label="Phone Number"
              color="secondary"
              className={classes.textField}
              error={state.contactPhoneNumber.error}
              helperText={state.contactPhoneNumber.message}
              value={state.contactPhoneNumber.value}
              onChange={handleContactPhoneNumberChange}
              inputProps={{
                onBlur: () =>
                  validateContactPhoneNumber(state.contactPhoneNumber.value)
              }}
              InputLabelProps={{
                shrink: true
              }}
            />
            <TextField
              fullWidth
              type="name"
              id="name-input"
              label="Name"
              color="secondary"
              className={classes.textField}
              error={state.contactName.error}
              helperText={state.contactName.message}
              value={state.contactName.value}
              onChange={handleContactNameChange}
              inputProps={{
                onBlur: () => validateContactName(state.contactName.value)
              }}
              InputLabelProps={{
                shrink: true
              }}
            />
            <TextField
              fullWidth
              type="surname"
              id="surname-input"
              label="Surname"
              color="secondary"
              className={classes.textField}
              error={state.contactSurname.error}
              helperText={state.contactSurname.message}
              value={state.contactSurname.value}
              onChange={handleContactSurnameChange}
              inputProps={{
                onBlur: () => validateContactSurname(state.contactSurname.value)
              }}
              InputLabelProps={{
                shrink: true
              }}
            />
          </div>
          <div className={classes.column}>
            <Typography
              className={classes.subtitle}
              variant="subtitle1"
              component="h3"
            >
              Lead Type
            </Typography>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="lead type"
                name="lead-type"
                value={state.leadType}
                onChange={handleLeadTypeChange}
              >
                <FormControlLabel
                  className={classes.formControlLabel}
                  value="COLD"
                  control={<Radio />}
                  label="Cold"
                />
                <FormControlLabel
                  className={classes.formControlLabel}
                  value="WARM"
                  control={<Radio />}
                  label="Warm"
                />
                <FormControlLabel
                  className={classes.formControlLabel}
                  value="QUALIFIED"
                  control={<Radio />}
                  label="Qualified"
                />
              </RadioGroup>
            </FormControl>
          </div>
        </ExpansionPanelDetails>
        <Divider />
        <ExpansionPanelActions>
          <Button className={classes.buttonReset} onClick={handleFormReset}>
            Reset
          </Button>
          <Button
            className={classes.buttonReset}
            onClick={() => handleViableProductsListToggle(true)}
          >
            View viable products
          </Button>
          <Button
            color="secondary"
            variant="contained"
            disabled={isAddButtonDisabled}
            onClick={handleAddLead}
          >
            Add Lead
          </Button>
        </ExpansionPanelActions>
      </ExpansionPanel>
      <ViableProductsDialog
        isOpen={state.isViableProductCategoriesOpen}
        actions={{
          handleDialogClose: () => handleViableProductsListToggle(false)
        }}
      />
    </div>
  );
}
