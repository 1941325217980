import React from "react";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1)
  },
  root: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    marginBottom: theme.spacing(4)
  }
}));

export default function FiltersBar(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container alignItems="center" spacing={3}>
        <Grid item xs={2}>
          <FormControl className={classes.formControl} fullWidth>
            <InputLabel shrink id="contact-count-label">
              Times Contacted
            </InputLabel>
            <Select
              labelId="contact-count-select-label"
              id="contact-count-select"
              value={props.contactCount}
              onChange={props.actions.handleContactCountChange}
              className={classes.select}
            >
              <MenuItem value={0}>Never</MenuItem>
              <MenuItem value={1}>Once</MenuItem>
              <MenuItem value={2}>Twice</MenuItem>
              <MenuItem value={3}>Thrice</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl className={classes.formControl} fullWidth>
            <InputLabel shrink id="select-lead-type-label">
              Lead Type
            </InputLabel>
            <Select
              labelId="lead-type-select-label"
              id="lead-type-select"
              value={props.leadType}
              onChange={props.actions.handleLeadTypeChange}
            >
              <MenuItem value="ALL">All</MenuItem>
              <MenuItem value="COLD">Cold</MenuItem>
              <MenuItem value="WARM">Warm</MenuItem>
              <MenuItem value="QUALIFIED">Qualified</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl className={classes.formControl} fullWidth>
            <InputLabel shrink id="select-last-contacted-label">
              Last Contacted
            </InputLabel>
            <Select
              labelId="last-contacted-select-label"
              id="last-contacted-select"
              value={props.lastContacted}
              onChange={props.actions.handleLastContactedChange}
            >
              <MenuItem value="ALL">{"N/A"}</MenuItem>
              <MenuItem value="WEEK">{"> A Week Ago"}</MenuItem>
              <MenuItem value="MONTH">{"> A Month Ago"}</MenuItem>
              <MenuItem value="YEAR">{"> A Year Ago"}</MenuItem>
              <MenuItem value="CUSTOM">Custom</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {props.lastContacted === "CUSTOM" && (
          <Grid item xs={3}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                id="start-date-picker-dialog"
                label="From"
                format="MM/dd/yyyy"
                value={props.fromDate}
                onChange={props.actions.handleFromDateChange}
                maxDate={props.toDate}
                KeyboardButtonProps={{
                  "aria-label": "change start date"
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        )}
        {props.lastContacted === "CUSTOM" && (
          <Grid item xs={3}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                color="secondary"
                id="end-date-picker-dialog"
                label="To"
                format="MM/dd/yyyy"
                value={props.toDate}
                maxDate={new Date()}
                onChange={props.actions.handleToDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change end date"
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        )}
      </Grid>
    </div>
  );
}
