import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const useStyles = makeStyles(theme => ({
  contentWrapper: {
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(4)
  }
}));

export default function EditInfoDialog(props) {
  const classes = useStyles();

  return (
    <Dialog
      open={props.isOpen}
      onClose={() => props.actions.handleClose()}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{props.copy.title}</DialogTitle>
      <DialogContent className={classes.contentWrapper}>
        <DialogContentText>{props.copy.explanation}</DialogContentText>
        {props.children}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.actions.handleClose()} color="primary">
          Cancel
        </Button>
        <Button
          disabled={props.hasError}
          onClick={() => props.actions.handleEdit()}
          variant="contained"
          color="primary"
        >
          Change
        </Button>
      </DialogActions>
    </Dialog>
  );
}
