import React from "react";
import _ from "lodash";
import { grey } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { Route, Switch, useLocation } from "react-router-dom";
import BusinessInfo from "../BusinessInfo";
import Container from "@material-ui/core/Container";
import CreateCampaignBar from "./components/CreateCampaignBar";
import EmailableRecordsTable from "./components/EmailableRecordsTable";
import FiltersBar from "./components/FiltersBar";
import moment from "moment";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";

const sections = {
  CREATE: "Create",
  PREVIOUS: "View Sent"
};

const useStyles = makeStyles(theme => ({
  header: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(4)
  },
  headingSection: {
    color: grey[700]
  },
  headingTitle: {
    color: theme.palette.common.white,
    marginBottom: theme.spacing(2)
  },
  innerContent: {
    padding: theme.spacing(3)
  }
}));

const initialState = {
  activeSection: {
    name: "Create",
    value: "CREATE"
  },
  lastContacted: "ALL",
  fromDate: moment("20000101", "YYYYMMDD").toDate(),
  toDate: moment().toDate(),
  contactCount: 0,
  leadType: "ALL",
  firstPaint: false,
  recordsSelected: []
};

export default function EmailCampaigns(props) {
  const classes = useStyles();
  const [state, setState] = React.useState(initialState);
  let location = useLocation();

  React.useEffect(() => {
    if (
      !state.firstPaint &&
      location.pathname === "/staff/email-campaigns" &&
      state.activeSection.value === "CREATE"
    ) {
      props.actions.loadEmailableRecords(
        state.contactCount,
        state.leadType,
        state.lastContacted,
        state.fromDate,
        state.toDate
      );
      setState({
        ...state,
        firstPaint: true
      });
    }
  }, [state, props, location]);

  const handleTabChange = (event, newActiveSection) => {
    if (newActiveSection === "CREATE") {
      props.actions.loadEmailableRecords(
        state.contactCount,
        state.leadType,
        state.lastContacted,
        state.fromDate,
        state.toDate
      );
    }

    setState({
      ...state,
      activeSection: {
        name: sections[newActiveSection],
        value: newActiveSection
      }
    });
  };

  const handleSelectionChange = newRows => {
    setState({
      ...state,
      recordsSelected: newRows
    });
  };

  const resetRowsSelected = () => {
    setState({
      ...state,
      recordsSelected: []
    });
  };

  const handleLastContactedChange = newValue => {
    let fromDate = state.fromDate;
    let toDate = state.toDate;

    switch (newValue) {
      case "WEEK":
        fromDate = moment("20000101", "YYYYMMDD").toDate();
        toDate = moment()
          .subtract(1, "week")
          .toDate();
        break;
      case "MONTH":
        fromDate = moment("20000101", "YYYYMMDD").toDate();
        toDate = moment()
          .subtract(1, "month")
          .toDate();
        break;
      case "YEAR":
        fromDate = moment("20000101", "YYYYMMDD").toDate();
        toDate = moment()
          .subtract(1, "year")
          .toDate();
        break;
      case "ALL":
        fromDate = moment("20000101", "YYYYMMDD").toDate();
        toDate = moment().toDate();
        break;
      default:
        fromDate = state.fromDate;
        toDate = state.toDate;
    }

    if (newValue !== "CUSTOM") {
      props.actions.loadEmailableRecords(
        state.contactCount,
        state.leadType,
        newValue,
        fromDate,
        toDate
      );
    }

    setState({
      ...state,
      fromDate,
      toDate,
      lastContacted: newValue
    });
  };

  const handleContactCountChange = newValue => {
    props.actions.loadEmailableRecords(
      newValue,
      state.leadType,
      state.lastContacted,
      state.fromDate,
      state.toDate
    );

    setState({
      ...state,
      contactCount: newValue
    });
  };

  const handleLeadTypeChange = newValue => {
    props.actions.loadEmailableRecords(
      state.contactCount,
      newValue,
      state.lastContacted,
      state.fromDate,
      state.toDate
    );

    setState({
      ...state,
      leadType: newValue
    });
  };

  const handleFromDateChange = newDate => {
    props.actions.loadEmailableRecords(
      state.contactCount,
      state.leadType,
      state.lastContacted,
      newDate,
      state.toDate
    );

    setState({
      ...state,
      fromDate: moment(newDate)
        .startOf("day")
        .toDate()
    });
  };

  const handleToDateChange = newDate => {
    if (moment(newDate).isBefore(state.fromDate)) {
      props.actions.loadEmailableRecords(
        state.contactCount,
        state.leadType,
        state.lastContacted,
        moment(newDate)
          .startOf("day")
          .toDate(),
        moment(newDate)
          .endOf("day")
          .toDate()
      );

      setState({
        ...state,
        fromDate: moment(newDate)
          .startOf("day")
          .toDate(),
        toDate: moment(newDate)
          .endOf("day")
          .toDate()
      });
    } else {
      props.actions.loadEmailableRecords(
        state.contactCount,
        state.leadType,
        state.lastContacted,
        state.fromDate,
        moment(newDate)
          .endOf("day")
          .toDate()
      );

      setState({
        ...state,
        toDate: moment(newDate)
          .endOf("day")
          .toDate()
      });
    }
  };

  return (
    <Switch>
      <Route exact path="/staff/email-campaigns">
        <div className={classes.header}>
          <Typography
            className={classes.headingSection}
            component="h1"
            variant="subtitle1"
          >
            {state.activeSection.name}
          </Typography>
          <Typography
            className={classes.headingTitle}
            component="h2"
            variant="h3"
          >
            Email Campaigns
          </Typography>
        </div>
        <Tabs
          aria-label="leads tabs"
          textColor="secondary"
          value={state.activeSection.value}
          className={classes.tab}
          onChange={handleTabChange}
        >
          {_.toPairs(sections).map(([value, name]) => (
            <Tab
              key={value}
              label={name}
              value={value}
              className={classes.tab}
            />
          ))}
        </Tabs>
        <Container className={classes.innerContent}>
          {state.activeSection.value === "CREATE" && (
            <CreateCampaignBar
              isLoading={props.isLoading}
              recordsSelected={state.recordsSelected}
              users={props.users}
              actions={{
                resetRowsSelected: () => resetRowsSelected(),
                createNewCampaign: (businessIds, senderInfo, templateId) =>
                  props.actions.createNewCampaign(
                    businessIds,
                    senderInfo,
                    templateId
                  )
              }}
            />
          )}
          {state.activeSection.value === "CREATE" && (
            <FiltersBar
              contactCount={state.contactCount}
              lastContacted={state.lastContacted}
              fromDate={state.fromDate}
              toDate={state.toDate}
              leadType={state.leadType}
              actions={{
                handleLastContactedChange: event =>
                  handleLastContactedChange(event.target.value),
                handleContactCountChange: event =>
                  handleContactCountChange(event.target.value),
                handleLeadTypeChange: event =>
                  handleLeadTypeChange(event.target.value),
                handleFromDateChange: fromDate =>
                  handleFromDateChange(fromDate),
                handleToDateChange: toDate => handleToDateChange(toDate)
              }}
            />
          )}
          {state.activeSection.value === "CREATE" && (
            <EmailableRecordsTable
              recordsSelected={state.recordsSelected}
              users={props.users}
              isLoading={props.isLoading}
              userType={props.userType}
              data={props.emailableRecords}
              contactCount={state.contactCount}
              actions={{
                handleSelectionChange: newRows => handleSelectionChange(newRows)
              }}
            />
          )}
        </Container>
      </Route>
      <Route path="/staff/email-campaigns/:businessId">
        <BusinessInfo
          userType={props.userType}
          isLoading={props.isLoading}
          businessInfo={props.businessInfo}
          recordsSelected={state.selectedRecords}
          actions={{
            restoreContact: id => props.actions.restoreContact(id),
            deleteContact: id => props.actions.deleteContact(id),
            loadBusinessInfo: id => props.actions.loadBusinessInfo(id),
            loadBusinessContacts: id => props.actions.loadBusinessContacts(id),
            validateBusinessName: name =>
              props.actions.validateBusinessName(name),
            validateBusinessWebsite: websiteUrl =>
              props.actions.validateBusinessWebsite(websiteUrl),
            editBusinessName: (id, businessName) =>
              props.actions.editBusinessName(id, businessName),
            editWebsiteUrl: (id, websiteUrl) =>
              props.actions.editWebsiteUrl(id, websiteUrl),
            editLeadType: (id, leadType) =>
              props.actions.editLeadType(id, leadType),
            createNewContact: (
              businessId,
              businessName,
              name,
              surname,
              jobTitle,
              email,
              phoneNumber
            ) =>
              props.actions.createNewContact(
                businessId,
                businessName,
                name,
                surname,
                jobTitle,
                email,
                phoneNumber
              ),
            editContactDetails: (id, newDetails) =>
              props.actions.editContactDetails(id, newDetails)
          }}
        />
      </Route>
    </Switch>
  );
}
