import React from 'react';
import { forwardRef } from 'react';
import _ from 'lodash';
import { common, green, red } from '@material-ui/core/colors';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import CircularProgress from '@material-ui/core/CircularProgress';
import Clear from '@material-ui/icons/Clear';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import MaterialTable from 'material-table';
import moment from 'moment';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import Tooltip from '@material-ui/core/Tooltip';
import ViewColumn from '@material-ui/icons/ViewColumn';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <Delete {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => (
    <SaveAlt className={useStyles().icon} {...props} ref={ref} />
  )),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => (
    <FirstPage {...props} style={{ color: common['white'] }} ref={ref} />
  )),
  LastPage: forwardRef((props, ref) => (
    <LastPage {...props} style={{ color: common['white'] }} ref={ref} />
  )),
  NextPage: forwardRef((props, ref) => (
    <ChevronRight {...props} style={{ color: common['white'] }} ref={ref} />
  )),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} style={{ color: common['white'] }} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => (
    <Clear {...props} style={{ color: common['white'] }} ref={ref} />
  )),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const useStyles = makeStyles((theme) => ({
  buttonGreen: {
    color: green[500],
  },
  buttonRed: {
    color: red[500],
  },
  icon: {
    color: theme.palette.common.white,
  },
  infoIcon: {
    color: theme.palette.secondary.main,
  },
  link: {
    color: theme.palette.secondary.main,
  },
  loadingOverlay: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: fade(theme.palette.common.black, 0.8),
  },
  margin: {
    marginRight: theme.spacing(2),
  },
}));

const headerStyle = {
  backgroundColor: '#171415',
};

const leadTypes = {
  COLD: 'Cold',
  WARM: 'Warm',
  QUALIFIED: 'Qualified',
};

export default function LeadsTable(props) {
  const classes = useStyles();
  const history = useHistory();

  const getTableTitle = (section, leadCount) => {
    switch (section) {
      case 'NEW':
        return `${leadCount} New Leads`;
      case 'APPROVED':
        return `${leadCount} Approved Leads`;
      case 'REJECTED':
        return `${leadCount} Rejected Leads`;
      case 'ALL':
        return `${leadCount} Total Leads`;
      default:
        return '';
    }
  };

  const getColumns = (userType) => {
    const columns = [
      {
        headerStyle,
        title: 'Business',
        field: 'name',
      },
      {
        headerStyle,
        title: 'Website',
        field: 'websiteUrl',
        render: (rowData) =>
          rowData.websiteUrl === 'Unknown' || rowData.websiteUrl === null ? (
            <span>No website provided</span>
          ) : (
            <a
              href={rowData.websiteUrl}
              rel="noopener noreferrer"
              target="_blank"
              className={classes.link}
            >
              Visit website
            </a>
          ),
      },
      {
        title: 'Type',
        field: 'type',
        lookup: leadTypes,
        initialEditValue: 'COLD',
        render: (rowData) => <span>{leadTypes[rowData.type]}</span>,
      },
      {
        headerStyle,
        title: 'Creation Date',
        field: 'creationDate',
        type: 'datetime',
        editable: 'never',
        defaultSort: 'desc',
        render: (rowData) => {
          return (
            <span>{`${moment(rowData.creationDate).format(
              'DD MMM YYYY'
            )}`}</span>
          );
        },
      },
    ];

    if (userType !== 'LEADS_AGENT' && userType !== 'SALES_AGENT') {
      columns.push({
        headerStyle,
        title: 'Created By',
        editable: 'never',
        field: 'createdBy',
      });
      columns.push({
        headerStyle,
        title: 'Review',
        field: 'status',
        editable: 'never',
        sorting: false,
        render: (rowData) => {
          if (rowData.salesInfo) {
            return (
              <ButtonGroup
                variant="contained"
                color="primary"
                aria-label="contained primary button group"
              >
                {rowData.status !== 'APPROVED' && (
                  <Tooltip title="Approve">
                    <Button
                      className={classes.buttonGreen}
                      onClick={() =>
                        props.actions.reviewLead(rowData.id, 'APPROVED')
                      }
                    >
                      <ThumbUpIcon />
                    </Button>
                  </Tooltip>
                )}
                {rowData.status !== 'REJECTED' && (
                  <Tooltip title="Reject">
                    <Button
                      className={classes.buttonRed}
                      onClick={() =>
                        props.actions.reviewLead(rowData.id, 'REJECTED')
                      }
                    >
                      <ThumbDownIcon />
                    </Button>
                  </Tooltip>
                )}
              </ButtonGroup>
            );
          } else {
            return (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => props.actions.cleanBusinessRecord(rowData.id)}
              >
                Clean
              </Button>
            );
          }
        },
      });
    }

    columns.push({
      headerStyle,
      title: '',
      field: 'action',
      searchable: false,
      export: false,
      sorting: false,
      render: (rowData) => {
        return (
          <Button
            aria-label="info"
            color="primary"
            variant="contained"
            onClick={() => history.push(`/staff/leads/${rowData.id}`)}
          >
            View Info
          </Button>
        );
      },
    });

    return columns;
  };

  const getShortenedString = (string, maxLength = 30) => {
    if (string.length > maxLength) {
      return _.truncate(string, { length: maxLength });
    }
    return string;
  };

  const getFormattedData = (data) => {
    return _.toPairs(data)
      .filter(
        ([id, info]) =>
          !info.isDeleted &&
          (info.status === props.section || props.section === 'ALL')
      )
      .map(([id, info]) => {
        return {
          id,
          createdBy: info.createdBy,
          creationDate: info.creationDate,
          name: getShortenedString(info.name),
          status: info.status,
          type: info.type,
          websiteUrl: info.websiteUrl.includes('http')
            ? info.websiteUrl
            : `https://${info.websiteUrl}`,
          salesInfo: info.salesInfo,
        };
      });
  };

  const formattedData = getFormattedData(props.data);

  return (
    <React.Fragment>
      <MaterialTable
        isLoading={props.isLoading}
        icons={tableIcons}
        columns={getColumns(props.userType)}
        data={formattedData}
        components={{
          OverlayLoading: (props) => (
            <div className={classes.loadingOverlay}>
              <CircularProgress color="primary" size={32} />
            </div>
          ),
        }}
        options={{
          headerStyle,
          pageSizeOptions: [],
          grouping: false,
          draggable: false,
          exportButton: true,
          exportAllData: true,
          exportFileName: 'Leads Data',
        }}
        title={getTableTitle(props.section, formattedData.length)}
        localization={{
          header: {
            actions: '',
          },
          body: {
            emptyDataSourceMessage: 'No leads to display',
          },
        }}
      />
    </React.Fragment>
  );
}
