import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";

import bannerImage from "./images/world-map.gif";

const useStyles = makeStyles(theme => ({
  container: {
    padding: `0 ${theme.spacing(2)}px`
  },
  imageMobile: {
    width: "80%",
    maxWidth: 300
  },
  imageWrapper: {
    textAlign: "center"
  },
  introText: {
    margin: `${theme.spacing(3)}px 0`,
    color: theme.palette.primary.light
  },
  rootWrapper: {
    padding: `${theme.spacing(20)}px 0`,
    backgroundColor: theme.palette.primary.main
  },
  taglineDesktop: {
    textAlign: "left",
    color: theme.palette.primary.contrastText
  },
  taglineMobile: {
    textAlign: "center",
    color: theme.palette.primary.contrastText
  }
}));

export default function HomeBanner(props) {
  const classes = useStyles();

  const goToContactForm = (activeLink, mobile) => event => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#contact-us"
    );

    if (anchor) {
      anchor.scrollIntoView({
        behavior: "smooth",
        block: "start"
      });
    }
  };

  return (
    <div className={classes.rootWrapper} id="home">
      <Container>
        <Grid
          container
          spacing={4}
          alignItems="center"
          justify="center"
          className={classes.container}
        >
          <Hidden mdUp>
            <Grid item xs={12} className={classes.imageWrapper}>
              <img
                src={bannerImage}
                className={classes.imageMobile}
                alt="World Map"
              />
            </Grid>
          </Hidden>
          <Grid item md={4} sm={12}>
            <Hidden smDown>
              <Typography
                variant="h3"
                component="h1"
                className={classes.taglineDesktop}
              >
                Produce Locally. Sell Globally.
              </Typography>
            </Hidden>
            <Hidden mdUp>
              <Typography
                variant="h4"
                component="h1"
                className={classes.taglineMobile}
              >
                Produce Locally. Sell Globally.
              </Typography>
            </Hidden>
            <Typography
              variant="subtitle1"
              component="p"
              className={classes.introText}
            >
              Diversify your business by selling your products online and
              worldwide. We're here to make the process easy!
            </Typography>
            <Hidden smDown>
              <Button
                variant="contained"
                color="secondary"
                onClick={goToContactForm()}
              >
                Get a quote
              </Button>
            </Hidden>
            <Hidden mdUp>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                fullWidth
                onClick={goToContactForm()}
              >
                Get a quote
              </Button>
            </Hidden>
          </Grid>
          <Hidden smDown>
            <Grid item md={8} className={classes.imageWrapper}>
              <img src={bannerImage} alt="World Map" />
            </Grid>
          </Hidden>
        </Grid>
      </Container>
    </div>
  );
}
