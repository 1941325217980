import React from "react";
import _ from "lodash";
import { grey } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import ComingSoon from "../ComingSoon";
import LeadsDashboard from "./components/LeadsDashboard";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";

const sections = {
  SALES: "Sales",
  EMAILS: "Email Campaigns",
  LEADS: "Leads"
};

const useStyles = makeStyles(theme => ({
  header: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(4)
  },
  headingSection: {
    color: grey[700]
  },
  headingTitle: {
    color: theme.palette.common.white,
    marginBottom: theme.spacing(2)
  },
  innerContent: {
    padding: theme.spacing(3)
  },
  tab: {
    color: theme.palette.secondary.light
  }
}));

const initialState = {
  activeSection: {
    name: "Leads",
    value: "LEADS"
  }
};

export default function Dashboard(props) {
  const classes = useStyles();
  const [state, setState] = React.useState(initialState);

  const handleTabChange = (event, newActiveSection) => {
    setState({
      ...state,
      activeSection: {
        name: sections[newActiveSection],
        value: newActiveSection
      }
    });
  };

  const getTabContent = () => {
    switch (state.activeSection.value) {
      case "LEADS":
        return <LeadsDashboard userType={props.userType} />;
      default:
        return <ComingSoon />;
    }
  };

  return (
    <div>
      <div className={classes.header}>
        <Typography
          className={classes.headingSection}
          component="h1"
          variant="subtitle1"
        >
          {state.activeSection.name}
        </Typography>
        <Typography
          className={classes.headingTitle}
          component="h2"
          variant="h3"
        >
          Dashboard
        </Typography>
      </div>
      {props.userType !== "LEADS_AGENT" && props.userType !== "LEADS_MANAGER" && (
        <Tabs
          aria-label="leads tabs"
          textColor="secondary"
          value={state.activeSection.value}
          className={classes.tab}
          onChange={handleTabChange}
        >
          {_.toPairs(sections).map(([value, name]) => (
            <Tab
              key={value}
              label={name}
              value={value}
              className={classes.tab}
            />
          ))}
        </Tabs>
      )}
      <div className={classes.innerContent}>{getTabContent()}</div>
    </div>
  );
}
