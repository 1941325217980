import React from "react";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useLocation } from "react-router-dom";
import firebase from "firebase/app";
import LinearProgress from "@material-ui/core/LinearProgress";
import moment from "moment";
import MuiAlert from "@material-ui/lab/Alert";
import PortalAppBar from "./components/PortalAppBar";
import PortalDrawer from "./components/PortalDrawer";
import SectionContent from "./components/SectionContent";
import Snackbar from "@material-ui/core/Snackbar";

import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    margin: 0,
    display: "flex"
  }
}));

const initialState = {
  version: "0.10.2",
  isLoaded: {
    user: false,
    userInfo: false
  },
  isLoading: {
    user: true,
    userInfo: true,
    signOut: false,
    leads: false,
    businessInfo: false,
    emailableRecords: false,
    salesRecords: false
  },
  isAlertOpen: {
    signOutFailed: false
  },
  userInfo: {
    id: "",
    name: "",
    surname: "",
    type: "",
    title: ""
  },
  leads: {},
  emailableRecords: {},
  salesRecords: {},
  users: {},
  businessInfo: {
    name: "Loading...",
    lastContacted: null,
    websiteUrl: "",
    status: "",
    creationDate: new Date(),
    leadInfo: {
      createdBy: "",
      type: "",
      status: ""
    }
  },
  section: ""
};

export default function StaffPortal() {
  const classes = useStyles();
  let history = useHistory();
  const location = useLocation();
  const [state, setState] = React.useState(initialState);

  React.useEffect(() => {
    if (location.pathname === "/staff") {
      history.push("/staff/dashboard");
    }
    !state.isLoaded.user &&
      firebase.auth().onAuthStateChanged(user => {
        if (user) {
          setState({
            ...state,
            isLoaded: {
              ...state.isLoaded,
              user: true
            },
            isLoading: {
              ...state.isLoading,
              user: false
            },
            userInfo: {
              ...state.userInfo,
              id: user.uid
            }
          });
        } else {
          history.push("/login");
        }
      });
    state.isLoaded.user &&
      !state.isLoaded.userInfo &&
      state.userInfo.id !== "" &&
      firebase
        .firestore()
        .collection("users")
        .get()
        .then(querySnapshot => {
          let users = {};
          querySnapshot.forEach(doc => {
            const userInfo = doc.data();

            users[doc.id] = {
              ...userInfo,
              id: doc.id
            };
          });

          setState({
            ...state,
            users,
            userInfo: {
              ...users[state.userInfo.id],
              id: state.userInfo.id
            },
            isLoaded: {
              ...state.isLoaded,
              userInfo: true
            },
            isLoading: {
              ...state.isLoading,
              userInfo: false
            }
          });
        });
  }, [state, history, location]);

  const loadBusinessInfo = id => {
    const businessRef = firebase
      .firestore()
      .collection("businesses")
      .doc(id);

    setState({
      ...state,
      businessInfo: initialState.businessInfo,
      isLoading: {
        ...state.isLoading,
        businessInfo: true
      }
    });

    return businessRef.get().then(doc => {
      const businessInfo = doc.data();

      return setState({
        ...state,
        businessInfo: {
          name: businessInfo.name,
          status: businessInfo.status,
          websiteUrl: businessInfo.websiteURL,
          lastContacted: businessInfo.lastContacted
            ? businessInfo.lastContacted.toDate()
            : null,
          creationDate: businessInfo.metadata.creationDate.toDate(),
          leadInfo: {
            createdBy: businessInfo.leadInfo.createdBy.name,
            status: businessInfo.leadInfo.status,
            type: businessInfo.leadInfo.type
          }
        },
        isLoading: {
          ...state.isLoading,
          businessInfo: false
        }
      });
    });
  };

  const loadLeads = (status, createdBy, type, fromDate, toDate) => {
    const businessesRef = firebase.firestore().collection("businesses");
    const usersRef = firebase.firestore().collection("users");

    let query = businessesRef
      .where("metadata.creationDate", ">=", fromDate)
      .where("metadata.creationDate", "<=", toDate);

    if (status !== "ALL") {
      query = query.where("leadInfo.status", "==", status);
    }

    if (createdBy !== "ALL") {
      query = query.where(
        "leadInfo.createdBy.ref",
        "==",
        usersRef.doc(createdBy)
      );
    }

    if (type !== "ALL") {
      query = query.where("leadInfo.type", "==", type);
    }

    setState({
      ...state,
      isLoading: {
        ...state.isLoading,
        leads: true
      }
    });

    return query.get().then(querySnapshot => {
      let leads = {};
      querySnapshot.forEach(doc => {
        const businessInfo = doc.data();

        leads[doc.id] = {
          creationDate: businessInfo.metadata
            ? businessInfo.metadata.creationDate.toDate()
            : null,
          name: businessInfo.name,
          websiteUrl: businessInfo.websiteURL,
          createdBy: businessInfo.leadInfo
            ? businessInfo.leadInfo.createdBy.name
            : null,
          type: businessInfo.leadInfo ? businessInfo.leadInfo.type : null,
          status: businessInfo.leadInfo ? businessInfo.leadInfo.status : null,
          createdByRef: businessInfo.leadInfo
            ? businessInfo.leadInfo.createdBy.ref
            : null,
          salesInfo: businessInfo.salesInfo,
          isDeleted: businessInfo.status === "DELETED"
        };
      });

      return setState({
        ...state,
        leads,
        isLoading: {
          ...state.isLoading,
          leads: false
        }
      });
    });
  };

  const loadSalesRecords = (recordType, salesPersonId, fromDate, toDate) => {
    const businessesRef = firebase.firestore().collection("businesses");
    let query = businessesRef.where("salesInfo.contactCount", ">", 0);

    if (recordType === "LEAD") {
      query = query
        .where("salesInfo.status", "==", "LEAD")
        .where("salesInfo.isLost", "==", false);
    } else if (recordType === "PROSPECT") {
      query = query
        .where("salesInfo.status", "==", "PROSPECT")
        .where("salesInfo.isLost", "==", false);
    } else if (recordType === "CLIENT") {
      query = query
        .where("salesInfo.status", "==", "CLIENT")
        .where("salesInfo.isLost", "==", false);
    } else {
      query = query.where("salesInfo.isLost", "==", true);
    }

    if (salesPersonId !== "ALL") {
      query = query.where("salesInfo.agent.id", "==", salesPersonId);
    }

    setState({
      ...state,
      isLoading: {
        ...state.isLoading,
        salesRecords: true
      }
    });

    return query.get().then(querySnapshot => {
      let salesRecords = {};
      querySnapshot.forEach(doc => {
        const businessInfo = doc.data();

        salesRecords[doc.id] = {
          lastContacted: businessInfo.lastContacted
            ? businessInfo.lastContacted.toDate()
            : null,
          name: businessInfo.name,
          websiteUrl: businessInfo.websiteURL,
          isLost: businessInfo.salesInfo ? businessInfo.salesInfo.isLost : null,
          isStale: businessInfo.salesInfo
            ? businessInfo.salesInfo.isStale
            : null,
          salesStatus: businessInfo.salesInfo
            ? businessInfo.salesInfo.status
            : null,
          contactCount: businessInfo.salesInfo
            ? businessInfo.salesInfo.contactCount
            : null,
          agentId: businessInfo.salesInfo
            ? businessInfo.salesInfo.agent.id
            : null,
          isDeleted: businessInfo.status === "DELETED"
        };
      });

      return setState({
        ...state,
        salesRecords,
        isLoading: {
          ...state.isLoading,
          salesRecords: false
        }
      });
    });
  };

  const loadEmailableRecords = (
    contactCount,
    leadType,
    lastContacted,
    fromDate,
    toDate
  ) => {
    const businessesRef = firebase.firestore().collection("businesses");

    let query = businessesRef
      .where("leadInfo.status", "==", "APPROVED")
      .where("salesInfo.status", "==", "LEAD")
      .where("salesInfo.isLost", "==", false)
      .where("salesInfo.contactCount", "==", contactCount);

    if (lastContacted !== "ALL") {
      query = query
        .where("lastContacted", ">=", fromDate)
        .where("lastContacted", "<=", toDate);
    }

    if (leadType !== "ALL") {
      query = query.where("leadInfo.type", "==", leadType);
    }

    setState({
      ...state,
      isLoading: {
        ...state.isLoading,
        emailableRecords: true
      }
    });

    return query.get().then(querySnapshot => {
      let emailableRecords = {};
      querySnapshot.forEach(doc => {
        const businessInfo = doc.data();

        emailableRecords[doc.id] = {
          lastContacted: businessInfo.lastContacted
            ? businessInfo.lastContacted.toDate()
            : null,
          name: businessInfo.name,
          leadType: businessInfo.leadInfo ? businessInfo.leadInfo.type : null,
          contactCount: businessInfo.salesInfo
            ? businessInfo.salesInfo.contactCount
            : null,
          agentId: businessInfo.salesInfo
            ? businessInfo.salesInfo.agent.id
            : null,
          agentRef: businessInfo.salesInfo
            ? businessInfo.salesInfo.agent.ref
            : null,
          isDeleted: businessInfo.status === "DELETED"
        };
      });

      return setState({
        ...state,
        emailableRecords,
        isLoading: {
          ...state.isLoading,
          emailableRecords: false
        }
      });
    });
  };

  const createNewLead = (
    businessInfo,
    leadType,
    creatorInfo,
    primaryContactInfo
  ) => {
    const creatorRef = firebase
      .firestore()
      .collection("users")
      .doc(creatorInfo.id);
    const businessRef = firebase
      .firestore()
      .collection("businesses")
      .doc();
    const contactRef = firebase
      .firestore()
      .collection("contacts")
      .doc();
    const batch = firebase.firestore().batch();

    const newBusinessInfo = {
      lastContacted: null,
      leadInfo: {
        createdBy: {
          name: creatorInfo.name,
          ref: creatorRef
        },
        status: "NEW",
        type: leadType
      },
      metadata: {
        creationDate: new Date(),
        lastCleaned: new Date()
      },
      name: businessInfo.name,
      salesInfo: {
        agent: {
          id: null,
          ref: null
        },
        contactCount: 0,
        invoiceValue: 0,
        isLost: false,
        isStale: false,
        status: "LEAD"
      },
      status: "ACTIVE",
      validationInfo: {
        name: stripText(businessInfo.name),
        websiteURL: stripText(businessInfo.websiteUrl)
      },
      websiteURL: businessInfo.websiteUrl
    };
    const newContactInfo = {
      business: {
        id: businessRef.id,
        name: businessInfo.name
      },
      email: primaryContactInfo.email,
      metadata: {
        creationDate: new Date(),
        lastCleaned: new Date()
      },
      name: primaryContactInfo.name,
      phoneNumber: primaryContactInfo.phoneNumber,
      role: "",
      status: "ACTIVE",
      surname: primaryContactInfo.surname,
      type: "PRIMARY",
      validationInfo: {
        email: stripText(primaryContactInfo.email),
        phoneNumber: stripText(primaryContactInfo.phoneNumber)
      }
    };
    batch.set(businessRef, newBusinessInfo);
    batch.set(contactRef, newContactInfo);

    setState({
      ...state,
      isLoading: {
        ...state.isLoading,
        leads: true
      }
    });

    return batch
      .commit()
      .then(() => {
        return setState({
          ...state,
          leads: {
            ...state.leads,
            [businessRef.id]: {
              creationDate: newBusinessInfo.metadata.creationDate,
              name: newBusinessInfo.name,
              websiteUrl: newBusinessInfo.websiteURL,
              createdBy: newBusinessInfo.leadInfo.createdBy.name,
              type: newBusinessInfo.leadInfo.type,
              status: newBusinessInfo.leadInfo.status,
              createdByRef: newBusinessInfo.leadInfo.createdBy.ref,
              salesInfo: newBusinessInfo.salesInfo,
              isDeleted: false
            }
          },
          isLoading: {
            ...state.isLoading,
            leads: false
          }
        });
      })
      .catch(error => {
        return setState({
          ...state,
          isLoading: {
            ...state.isLoading,
            leads: false
          }
        });
      });
  };

  const getPrimaryBusinessContactId = businessId => {
    const contactsQuery = firebase
      .firestore()
      .collection("contacts")
      .where("type", "==", "PRIMARY")
      .where("business.id", "==", businessId);

    return contactsQuery.get().then(querySnapshot => {
      let contactIds = [];
      querySnapshot.forEach(doc => {
        contactIds.push(doc.id);
      });
      return contactIds[0];
    });
  };

  const loadBusinessContacts = businessId => {
    const contactsQuery = firebase
      .firestore()
      .collection("contacts")
      .where("business.id", "==", businessId);

    return contactsQuery.get().then(querySnapshot => {
      let contacts = [];
      querySnapshot.forEach(doc => {
        const contactInfo = doc.data();
        contacts.push({
          id: doc.id,
          name: contactInfo.name,
          surname: contactInfo.surname,
          emailAddress: contactInfo.email,
          phoneNumber: contactInfo.phoneNumber,
          isPrimary: contactInfo.type === "PRIMARY",
          jobTitle: contactInfo.role,
          status: contactInfo.status
        });
      });
      return contacts;
    });
  };

  const createNewCampaign = async (businessIds, senderInfo, templateId) => {
    const campaignRef = firebase
      .firestore()
      .collection("campaigns")
      .doc();

    setState({
      ...state,
      isLoading: {
        ...state.isLoading,
        emailableRecords: true
      }
    });

    let contactIds = await Promise.all(
      businessIds.map(businessId => getPrimaryBusinessContactId(businessId))
    );

    const newCampaignInfo = {
      templateId,
      senderInfo,
      sendDate: new Date(),
      businesses: businessIds.map(id =>
        firebase
          .firestore()
          .collection("businesses")
          .doc(id)
      ),
      contacts: contactIds.map(id =>
        firebase
          .firestore()
          .collection("contacts")
          .doc(id)
      )
    };

    return campaignRef
      .set(newCampaignInfo)
      .then(() => {
        const updatedEmailableRecords = _.fromPairs(
          businessIds.map(id => {
            return [
              id,
              {
                ...state.emailableRecords[id],
                lastContacted: new Date(),
                contactCount: state.emailableRecords[id].contactCount + 1,
                agentName: `${senderInfo.name} ${senderInfo.surname}`,
                agentRef: senderInfo.ref
              }
            ];
          })
        );

        return setState({
          ...state,
          emailableRecords: {
            ...state.emailableRecords,
            ...updatedEmailableRecords
          },
          isLoading: {
            ...state.isLoading,
            emailableRecords: false
          }
        });
      })
      .catch(error => {
        return setState({
          ...state,
          isLoading: {
            ...state.isLoading,
            emailableRecords: false
          }
        });
      });
  };

  const createNewContact = async (
    businessId,
    businessName,
    name,
    surname,
    jobTitle,
    email,
    phoneNumber
  ) => {
    const newContactRef = firebase
      .firestore()
      .collection("contacts")
      .doc();

    setState({
      ...state,
      isLoading: {
        ...state.isLoading,
        businessInfo: true
      }
    });

    const newContactInfo = {
      email,
      name,
      surname,
      phoneNumber,
      status: "ACTIVE",
      type: "SECONDARY",
      role: jobTitle,
      validationInfo: {
        email: stripText(email),
        phoneNumber: stripText(phoneNumber)
      },
      metadata: {
        creationDate: new Date(),
        lastCleaned: new Date()
      },
      business: {
        id: businessId,
        name: businessName
      }
    };

    return newContactRef
      .set(newContactInfo)
      .then(() => {
        return setState({
          ...state,
          isLoading: {
            ...state.isLoading,
            businessInfo: false
          }
        });
      })
      .catch(error => {
        return setState({
          ...state,
          isLoading: {
            ...state.isLoading,
            businessInfo: false
          }
        });
      });
  };

  const makeContactPrimary = (id, otherContacts) => {
    const contactsRef = firebase.firestore().collection("contacts");
    const batch = firebase.firestore().batch();

    batch.update(contactsRef.doc(id), {
      type: "PRIMARY"
    });

    otherContacts.map(id =>
      batch.update(contactsRef.doc(id), {
        type: "SECONDARY"
      })
    );

    return batch.commit();
  };

  const editContactDetails = (id, newDetails) => {
    const contactRef = firebase.firestore().collection("contacts");
    const validationInfo = {
      email: stripText(newDetails.email),
      phoneNumber: stripText(newDetails.phoneNumber)
    };

    return contactRef.doc(id).update({
      validationInfo,
      name: newDetails.name,
      surname: newDetails.surname,
      phoneNumber: newDetails.phoneNumber,
      role: newDetails.role,
      email: newDetails.email
    });
  };

  const restoreContact = id => {
    const contactRef = firebase.firestore().collection("contacts");

    return contactRef.doc(id).update({
      status: "ACTIVE"
    });
  };

  const deleteContact = id => {
    const contactRef = firebase.firestore().collection("contacts");

    return contactRef.doc(id).update({
      status: "DELETED"
    });
  };

  const editBusinessName = (id, newName) => {
    const businessesRef = firebase.firestore().collection("businesses");
    const strippedName = stripText(newName);

    setState({
      ...state,
      isLoading: {
        ...state.isLoading,
        businessInfo: true
      }
    });

    return businessesRef
      .doc(id)
      .update({
        name: newName,
        "validationInfo.name": strippedName
      })
      .then(() => {
        return setState({
          ...state,
          businessInfo: {
            ...state.businessInfo,
            name: newName
          },
          isLoading: {
            ...state.isLoading,
            businessInfo: false
          }
        });
      })
      .catch(error => {
        return setState({
          ...state,
          isLoading: {
            ...state.isLoading,
            businessInfo: false
          }
        });
      });
  };

  const editWebsiteUrl = (id, newWebsiteUrl) => {
    const businessesRef = firebase.firestore().collection("businesses");
    const strippedUrl = stripText(newWebsiteUrl);

    setState({
      ...state,
      isLoading: {
        ...state.isLoading,
        businessInfo: true
      }
    });

    return businessesRef
      .doc(id)
      .update({
        websiteURL: newWebsiteUrl,
        "validationInfo.websiteURL": strippedUrl
      })
      .then(() => {
        return setState({
          ...state,
          businessInfo: {
            ...state.businessInfo,
            websiteUrl: newWebsiteUrl
          },
          isLoading: {
            ...state.isLoading,
            businessInfo: false
          }
        });
      })
      .catch(error => {
        return setState({
          ...state,
          isLoading: {
            ...state.isLoading,
            businessInfo: false
          }
        });
      });
  };

  const editLeadType = (id, newLeadType) => {
    const businessesRef = firebase.firestore().collection("businesses");

    setState({
      ...state,
      isLoading: {
        ...state.isLoading,
        businessInfo: true
      }
    });

    return businessesRef
      .doc(id)
      .update({
        "leadInfo.type": newLeadType
      })
      .then(() => {
        return setState({
          ...state,
          businessInfo: {
            ...state.businessInfo,
            leadInfo: {
              ...state.businessInfo.leadInfo,
              type: newLeadType
            }
          },
          isLoading: {
            ...state.isLoading,
            businessInfo: false
          }
        });
      })
      .catch(error => {
        return setState({
          ...state,
          isLoading: {
            ...state.isLoading,
            businessInfo: false
          }
        });
      });
  };

  const reviewLead = (id, feedback) => {
    const businessesRef = firebase.firestore().collection("businesses");

    setState({
      ...state,
      isLoading: {
        ...state.isLoading,
        leads: true
      }
    });

    return businessesRef
      .doc(id)
      .update({
        "leadInfo.status": feedback
      })
      .then(() => {
        return setState({
          ...state,
          leads: {
            ...state.leads,
            [id]: {
              ...state.leads[id],
              status: feedback
            }
          },
          isLoading: {
            ...state.isLoading,
            leads: false
          }
        });
      })
      .catch(error => {
        return setState({
          ...state,
          isLoading: {
            ...state.isLoading,
            leads: false
          }
        });
      });
  };

  const makeLeadProspect = id => {
    const businessesRef = firebase.firestore().collection("businesses");

    setState({
      ...state,
      isLoading: {
        ...state.isLoading,
        salesRecords: true
      }
    });

    return businessesRef
      .doc(id)
      .update({
        lastContacted: new Date(),
        "salesInfo.status": "PROSPECT"
      })
      .then(() => {
        return setState({
          ...state,
          salesRecords: {
            ...state.salesRecords,
            [id]: {
              ...state.salesRecords[id],
              lastContacted: new Date(),
              salesStatus: "PROSPECT"
            }
          },
          isLoading: {
            ...state.isLoading,
            salesRecords: false
          }
        });
      })
      .catch(error => {
        return setState({
          ...state,
          isLoading: {
            ...state.isLoading,
            salesRecords: false
          }
        });
      });
  };

  const makeLeadLost = id => {
    const businessesRef = firebase.firestore().collection("businesses");

    setState({
      ...state,
      isLoading: {
        ...state.isLoading,
        salesRecords: true
      }
    });

    return businessesRef
      .doc(id)
      .update({
        "salesInfo.isLost": true
      })
      .then(() => {
        return setState({
          ...state,
          salesRecords: {
            ...state.salesRecords,
            [id]: {
              ...state.salesRecords[id],
              isLost: true
            }
          },
          isLoading: {
            ...state.isLoading,
            salesRecords: false
          }
        });
      })
      .catch(error => {
        return setState({
          ...state,
          isLoading: {
            ...state.isLoading,
            salesRecords: false
          }
        });
      });
  };

  const makeLeadClient = id => {
    const businessesRef = firebase.firestore().collection("businesses");

    setState({
      ...state,
      isLoading: {
        ...state.isLoading,
        salesRecords: true
      }
    });

    return businessesRef
      .doc(id)
      .update({
        lastContacted: new Date(),
        "salesInfo.status": "CLIENT"
      })
      .then(() => {
        return setState({
          ...state,
          salesRecords: {
            ...state.salesRecords,
            [id]: {
              ...state.salesRecords[id],
              lastContacted: new Date(),
              salesStatus: "CLIENT"
            }
          },
          isLoading: {
            ...state.isLoading,
            salesRecords: false
          }
        });
      })
      .catch(error => {
        return setState({
          ...state,
          isLoading: {
            ...state.isLoading,
            salesRecords: false
          }
        });
      });
  };

  const logNewContactDate = id => {
    const businessesRef = firebase.firestore().collection("businesses");

    setState({
      ...state,
      isLoading: {
        ...state.isLoading,
        salesRecords: true
      }
    });

    return businessesRef
      .doc(id)
      .update({
        lastContacted: new Date()
      })
      .then(() => {
        return setState({
          ...state,
          salesRecords: {
            ...state.salesRecords,
            [id]: {
              ...state.salesRecords[id],
              lastContacted: new Date()
            }
          },
          isLoading: {
            ...state.isLoading,
            salesRecords: false
          }
        });
      })
      .catch(error => {
        return setState({
          ...state,
          isLoading: {
            ...state.isLoading,
            salesRecords: false
          }
        });
      });
  };

  const validateBusinessWebsite = websiteUrl =>
    new Promise((resolve, reject) => {
      const strippedUrl = stripText(websiteUrl);
      const query = firebase
        .firestore()
        .collection("businesses")
        .where("validationInfo.websiteURL", "==", strippedUrl);

      if (websiteUrl.length === 0) {
        resolve({
          error: true,
          message: "Required"
        });
      } else if (websiteUrl.length > 300) {
        resolve({
          error: true,
          message: "Too many characters (max. 300)"
        });
      }

      setState({
        ...state,
        isLoading: {
          ...state.isLoading,
          leads: true
        }
      });

      query.get().then(querySnapshot => {
        let exists = false;

        querySnapshot.forEach(doc => {
          exists = true;
        });

        setState({
          ...state,
          isLoading: {
            ...state.isLoading,
            leads: false
          }
        });

        if (exists) {
          resolve({
            error: true,
            message: "Already exists"
          });
        } else {
          resolve({
            error: false,
            message: ""
          });
        }
      });
    });

  const validateBusinessName = name =>
    new Promise((resolve, reject) => {
      const strippedName = stripText(name);
      const query = firebase
        .firestore()
        .collection("businesses")
        .where("validationInfo.name", "==", strippedName);

      if (name.length === 0) {
        resolve({
          error: true,
          message: "Required"
        });
      } else if (name.length > 300) {
        resolve({
          error: true,
          message: "Too many characters (max. 300)"
        });
      }

      setState({
        ...state,
        isLoading: {
          ...state.isLoading,
          leads: true
        }
      });

      query.get().then(querySnapshot => {
        let exists = false;

        querySnapshot.forEach(doc => {
          exists = true;
        });

        setState({
          ...state,
          isLoading: {
            ...state.isLoading,
            leads: false
          }
        });

        if (exists) {
          resolve({
            error: true,
            message: "Already exists"
          });
        } else {
          resolve({
            error: false,
            message: ""
          });
        }
      });
    });

  const isEmailFormatCorrect = email => {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w+)+$/.test(email)) {
      return true;
    }
    return false;
  };

  const isPhoneNumberFormatCorrect = phoneNumber => {
    if (/^[0-9]*$/.test(phoneNumber)) {
      return true;
    }
    return false;
  };

  const validateContactEmail = email =>
    new Promise((resolve, reject) => {
      const strippedEmail = stripText(email);
      const query = firebase
        .firestore()
        .collection("contacts")
        .where("validationInfo.email", "==", strippedEmail);

      if (email.length === 0) {
        resolve({
          error: true,
          message: "Required"
        });
      } else if (email.length > 300) {
        resolve({
          error: true,
          message: "Too many characters (max. 300)"
        });
      } else if (!isEmailFormatCorrect(email)) {
        resolve({
          error: true,
          message: "Invalid email address"
        });
      }

      setState({
        ...state,
        isLoading: {
          ...state.isLoading,
          leads: true
        }
      });

      query.get().then(querySnapshot => {
        let exists = false;

        querySnapshot.forEach(doc => {
          exists = true;
        });

        setState({
          ...state,
          isLoading: {
            ...state.isLoading,
            leads: false
          }
        });

        if (exists) {
          resolve({
            error: true,
            message: "Already exists"
          });
        } else {
          resolve({
            error: false,
            message: ""
          });
        }
      });
    });

  const validateContactPhoneNumber = phoneNumber =>
    new Promise((resolve, reject) => {
      const query = firebase
        .firestore()
        .collection("contacts")
        .where("validationInfo.phoneNumber", "==", phoneNumber);

      if (phoneNumber.length === 0) {
        resolve({
          error: false,
          message: ""
        });
      } else if (!isPhoneNumberFormatCorrect(phoneNumber)) {
        resolve({
          error: true,
          message: "Only enter digits [0-9]"
        });
      } else if (phoneNumber.length !== 10) {
        resolve({
          error: true,
          message: "Must have 10 digits"
        });
      }

      setState({
        ...state,
        isLoading: {
          ...state.isLoading,
          leads: true
        }
      });

      query.get().then(querySnapshot => {
        let exists = false;

        querySnapshot.forEach(doc => {
          exists = true;
        });

        setState({
          ...state,
          isLoading: {
            ...state.isLoading,
            leads: false
          }
        });

        if (exists) {
          resolve({
            error: true,
            message: "Already exists"
          });
        } else {
          resolve({
            error: false,
            message: ""
          });
        }
      });
    });

  const validateContactNameSurname = text =>
    new Promise((resolve, reject) => {
      if (text.length > 300) {
        resolve({
          error: true,
          message: "Too many characters (max. 300)"
        });
      } else {
        resolve({
          error: false,
          message: ""
        });
      }
    });

  const stripText = text => {
    return _.replace(
      _.lowerCase(text),
      /\s+|\b(\w*http\w*)\b|\b(\w*https\w*)\b|\b(\w*www\w*)\b/g,
      ""
    );
  };

  const cleanRecord = id => {
    const businessRef = firebase
      .firestore()
      .collection("businesses")
      .doc(id);

    let status = "LEAD";
    let isLost = false;
    let isStale = moment(state.leads[id].lastContacted).isBefore(
      moment().subtract(2, "weeks")
    );
    const contactCount =
      state.leads[id].overallStatus === "LEAD_CONTACTED" ? 1 : 0;

    const prospectStatuses = [
      "PROSPECT_NEW",
      "PROSPECT_STALE",
      "PROSPECT_INVOICED",
      "PROSPECT_LOST"
    ];
    const customerStatuses = [
      "DELETED",
      "CUSTOMER_NEW",
      "CUSTOMER_STANDARD",
      "CUSTOMER_CANCELLED"
    ];

    if (prospectStatuses.includes(state.leads[id].overallStatus)) {
      status = "PROSPECT";
    } else if (customerStatuses.includes(state.leads[id].overallStatus)) {
      status = "CUSTOMER";
    }

    if (
      ["PROSPECT_LOST", "LEAD_DEAD"].includes(state.leads[id].overallStatus)
    ) {
      isLost = true;
    }

    const updateInfo = {
      "metadata.lastCleaned": new Date(),
      salesInfo: {
        isLost,
        status,
        isStale,
        contactCount,
        agent: {
          ref: null,
          name: null
        },
        invoiceValue: 0
      }
    };

    setState({
      ...state,
      isLoading: {
        ...state.isLoading,
        leads: true
      }
    });

    return businessRef
      .update(updateInfo)
      .then(() => {
        return setState({
          ...state,
          leads: {
            ...state.leads,
            [id]: {
              ...state.leads[id],
              salesInfo: updateInfo.salesInfo
            }
          },
          isLoading: {
            ...state.isLoading,
            leads: false
          }
        });
      })
      .catch(error => {
        return setState({
          ...state,
          isLoading: {
            ...state.isLoading,
            leads: false
          }
        });
      });
  };

  const getCurrentSection = path => {
    if (path.includes("leads")) {
      return "leads";
    } else if (path.includes("dashboard")) {
      return "dashboard";
    } else if (path.includes("sales")) {
      return "sales";
    } else if (path.includes("email-campaigns")) {
      return "email-campaigns";
    } else {
      return "";
    }
  };

  const handleSignOutAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setState({
      ...state,
      isAlertOpen: {
        ...state.isAlertOpen,
        signOutFailed: false
      }
    });
  };

  const handleSectionChange = newSection => event => {
    history.push(`/staff/${newSection}`);
  };

  const handleUserSignOut = () => event => {
    event.preventDefault();

    setState({
      ...state,
      isLoading: {
        ...state.isLoading,
        signOut: true
      }
    });

    firebase
      .auth()
      .signOut()
      .then(() => {
        history.push("/login");
      })
      .catch(error => {
        setState({
          ...state,
          isLoading: {
            ...state.isLoading,
            signOut: false
          },
          isAlertOpen: {
            ...state.isAlertOpen,
            signOutFailed: true
          }
        });
      });
  };

  if (state.isLoading.user || state.isLoading.userInfo) {
    return <LinearProgress color="secondary" />;
  } else {
    return (
      <div className={classes.root}>
        <PortalAppBar
          isLoading={state.isLoading.signOut}
          version={state.version}
          actions={{
            handleUserSignOut: () => handleUserSignOut()
          }}
        />
        <PortalDrawer
          activeSection={getCurrentSection(location.pathname)}
          isLoading={state.isLoading.userInfo}
          name={`${state.userInfo.name} ${state.userInfo.surname}`}
          userType={state.userInfo.type}
          title={state.userInfo.title}
          actions={{
            handleSectionChange: newSection => handleSectionChange(newSection)
          }}
        />
        <SectionContent
          userId={state.userInfo.id}
          userName={`${state.userInfo.name} ${state.userInfo.surname}`}
          userType={state.userInfo.type}
          section={state.section}
          isLoading={
            state.isLoading.userInfo ||
            state.isLoading.signOut ||
            state.isLoading.leads ||
            state.isLoading.emailableRecords ||
            state.isLoading.businessInfo ||
            state.isLoading.salesRecords
          }
          isUserLoading={state.isLoading.userInfo}
          leads={state.leads}
          emailableRecords={state.emailableRecords}
          salesRecords={state.salesRecords}
          users={state.users}
          businessInfo={state.businessInfo}
          actions={{
            createNewLead: (
              businessInfo,
              leadType,
              creatorInfo,
              primaryContactInfo
            ) =>
              createNewLead(
                businessInfo,
                leadType,
                creatorInfo,
                primaryContactInfo
              ),
            loadSalesRecords: (recordType, salesPersonId, fromDate, toDate) =>
              loadSalesRecords(recordType, salesPersonId, fromDate, toDate),
            makeLeadProspect: id => makeLeadProspect(id),
            makeLeadLost: id => makeLeadLost(id),
            makeLeadClient: id => makeLeadClient(id),
            logNewContactDate: id => logNewContactDate(id),
            createNewCampaign: (
              businessIds,
              contactIds,
              senderInfo,
              templateId
            ) =>
              createNewCampaign(
                businessIds,
                contactIds,
                senderInfo,
                templateId
              ),
            createNewContact: (
              businessId,
              businessName,
              name,
              surname,
              jobTitle,
              email,
              phoneNumber
            ) =>
              createNewContact(
                businessId,
                businessName,
                name,
                surname,
                jobTitle,
                email,
                phoneNumber
              ),
            loadBusinessInfo: id => loadBusinessInfo(id),
            loadBusinessContacts: id => loadBusinessContacts(id),
            loadLeads: (status, createdBy, type, fromDate, toDate) =>
              loadLeads(status, createdBy, type, fromDate, toDate),
            cleanBusinessRecord: id => cleanRecord(id),
            reviewLead: (id, feedback) => reviewLead(id, feedback),
            editBusinessName: (id, name) => editBusinessName(id, name),
            editWebsiteUrl: (id, websiteUrl) => editWebsiteUrl(id, websiteUrl),
            editLeadType: (id, leadType) => editLeadType(id, leadType),
            restoreContact: id => restoreContact(id),
            deleteContact: id => deleteContact(id),
            editContactDetails: (id, newDetails) =>
              editContactDetails(id, newDetails),
            makeContactPrimary: (id, otherContacts) =>
              makeContactPrimary(id, otherContacts),
            validateBusinessName: name => validateBusinessName(name),
            validateBusinessWebsite: websiteUrl =>
              validateBusinessWebsite(websiteUrl),
            validateContactEmail: email => validateContactEmail(email),
            validateContactPhoneNumber: phoneNumber =>
              validateContactPhoneNumber(phoneNumber),
            validateContactNameSurname: text =>
              validateContactNameSurname(text),
            loadEmailableRecords: (
              contactCount,
              leadType,
              lastContacted,
              fromDate,
              toDate
            ) =>
              loadEmailableRecords(
                contactCount,
                leadType,
                lastContacted,
                fromDate,
                toDate
              )
          }}
        />
        <Snackbar
          open={state.isAlertOpen.signOutFailed}
          autoHideDuration={5000}
          onClose={handleSignOutAlertClose}
        >
          <Alert onClose={handleSignOutAlertClose} severity="error">
            Log out failed. Please check your internet connection.
          </Alert>
        </Snackbar>
      </div>
    );
  }
}
